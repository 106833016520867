import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CurrencyFormat from 'react-currency-format';
import * as qs from 'querystring';
import { ui } from '@hawthorn/services/ui';
import SummaryConfig from './summary-config';
import SummaryChart from './summary-chart';

const styles = theme => ({
  years: {
    ...ui.plannerStyles.yearsContainer,
    position: 'absolute',
    top: 0
  },

  year: {
    ...ui.plannerStyles.yearColumn,
    padding: '0 10px 1em',
    boxSizing: 'border-box',
    // Kind of dumb but I need to limit the height on this div or it causes the page to continuously
    // grow when you scroll down the screen
    height: 1
  },

  forecastTitle: {
    margin: 0,
    padding: '2px 11px',
    color: ui.colours.foregroundLight,
    fontSize: 20
  },

  expandButton: {
    position: 'fixed',
    padding: '10px 24px 0',
    bottom: 105,
    // left: (ui.plannerStyles.yearsContainer.left / 3),
    right: 20,
    color: '#fff',
    width: 206,
    height: 54,
    fontSize: 16,
    background: ui.colours.primaryLight,
    border: 'none',
    borderRadius: '30px 30px 0 0',
    // transform: 'rotate(-45deg)',
    transition: 'bottom 0.2s ease-in-out 0s',
    cursor: 'pointer',
    outline: 'none'
  },

  expandButtonIcon: {
    // transform: 'rotate(45deg)'
  },

  subtotal: {
    paddingTop: 3,
    textAlign: 'right'
  },

  balance: {
    paddingTop: 3,
    textAlign: 'right',
    fontWeight: 'bold',
    borderTop: '1px solid rgba(255, 255, 255, 0.8)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.8)'
  },

  subtitle: {
    marginRight: '0.5em',
    float: 'left',
    textTransform: 'uppercase',
    fontSize: 11,
    color: '#fff',
    opacity: 0.8
  },

  disclaimer: {
    position: 'absolute',
    top: ui.plannerStyles.summaryContainer.expandedHeight - 35,
    left: ui.plannerStyles.yearsContainer.left + 10,
    color: '#fff',
    fontSize: 11,
  }
});

class SummaryPanel extends Component {
  state = {
    expanded: false,
    containerPosition: 0
  }

  componentDidMount() {
    // Disabled because I'm not sure I like the behaviour yet...
    /*
    const { location } = this.props;

    // Expand summary if specified in query param
    const query = qs.parse(location.search.substring(1));

    if (query.expand === 'true') {
      this.setState({
        expanded: true
      });
    }
    */

    // Watch for scrolling the main board and force this component to stick to the bottom
    document.getElementById('planning-board').addEventListener('scroll', () =>
      this.setState({ containerPosition: 0 - document.getElementById('planning-board').scrollTop }));
  }

  getContainerStyle(years) {
    return {
      position: 'absolute',
      bottom: this.state.containerPosition,
      backgroundColor: ui.colours.primaryLight,
      width: ui.getYearContainerWidth(years.length),
      height: this.state.expanded ? ui.plannerStyles.summaryContainer.expandedHeight : ui.plannerStyles.summaryContainer.height,
      transition: 'height 0.2s ease-in-out 0s',
      overflow: 'hidden',
      zIndex: 200
    }
  }

  getNetStyle(yearForecast) {
    return {
      // display: 'block',
      // marginBottom: 5,
      // fontSize: 22,
      // color: ui.colours.foregroundLight,
      backgroundColor: (yearForecast.net > -1 ? 'transparent' : ui.colours.negativeDark),
      borderRadius: 4
    }
  }

  getBalanceStyle(yearForecast) {
    return {
      // opacity: 0.8,
      // color: (yearForecast.balance > -1 ? ui.colours.positive : ui.colours.negative),
      // fontWeight: 'normal'

      backgroundColor: (yearForecast.balance > -1 ? 'transparent' : ui.colours.negativeDark),
      textAlign: 'right',
      borderRadius: 4
    }
  }

  getContributionAmount(yearForecast) {
    return yearForecast.credit.length > 0 ? yearForecast.credit[0].amount : 0;
  }

  toggleExpand() {
   this.setState({ expanded: !this.state.expanded });
  }
  
  render() {
    const { classes, forecast } = this.props;
    const { expanded } = this.state;

    const years = Object.keys(forecast.years);

    return (
      <div style={this.getContainerStyle(years)}>
        <button className={classes.expandButton} style={{ bottom: (expanded ? ui.plannerStyles.summaryContainer.expandedHeight : ui.plannerStyles.summaryContainer.height) }} onClick={() => this.toggleExpand()}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <span>View Plan</span>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                className={classes.expandButtonIcon}
                icon={expanded ? 'chevron-down' : 'chevron-up'} />
            </Grid>
          </Grid>
        </button>

        {expanded && <SummaryConfig forecast={forecast} /> }

        {expanded && <SummaryChart forecast={forecast} /> }

        <ul className={classes.years}>
          {years.map(year =>
            <li key={year} className={classes.year}>
              <Typography gutterBottom variant="h2" className={classes.forecastTitle} style={this.getBalanceStyle(forecast.years[year])}>
                <CurrencyFormat
                  value={Math.abs(forecast.years[year].balance)}
                  isNumericString={true}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix={forecast.years[year].balance > 0 ? '$' : '($'}
                  suffix={forecast.years[year].balance > 0 ? '' : ')'} />
              </Typography>
             
             {/* Contributions 
             <div className={classes.subtotal}>
              <span className={classes.subtitle}>Contributions</span>

                <CurrencyFormat
                  style={{ color: ui.colours.positive }}
                  value={this.getContributionAmount(forecast.years[year])}
                  isNumericString={true}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix={'$'} />
              </div>
            */}

             {/* Expenditure 
             <div className={classes.subtotal}>
              <span className={classes.subtitle}>Expenditure</span>

              
                <CurrencyFormat
                  style={{ color: ui.colours.negative }}
                  value={forecast.years[year].debitTotal * -1}
                  isNumericString={true}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix={'$'} />
              </div>
              */}

              {/* Balance 
              <div className={classes.balance}>
                <span className={classes.subtitle}>Balance *</span>

                <CurrencyFormat
                  style={this.getBalanceStyle(forecast.years[year])}
                  value={forecast.years[year].balance}
                  isNumericString={true}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix={'$'} />
              </div>
              */}
            </li>
          )}
        </ul>

        {/* expanded &&
          <div className={classes.disclaimer}>
            <p>* Closing balances have allowances for interest and tax where applicable</p>
          </div>
        */}
      </div>
    )
  }
}
  
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(SummaryPanel));
