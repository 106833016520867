import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
  navbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0 1em'
  },

  addItemButton: {
    color: 'white'
  }
});

class NavigationProperties extends Component {
  render() {
    const {
      classes,
      createProperty,
      authUser
    } = this.props;

    return (
      <div className={classes.navbar}>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = (dispatch) => ({
  createProperty: () => dispatch({ type: 'NEW_PROPERTY' }),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NavigationProperties);
