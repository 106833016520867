import 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose';
import { MuiThemeProvider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import debounce from 'lodash/debounce';
import DateFnsUtils from '@date-io/date-fns';
import { ui } from '@hawthorn/services/ui';
import { budgeter } from '@hawthorn/services/budgeter';

const formBoxShadow = `2px 2px 4px 0px inset rgba(0,0,0,0.3)`;

const ContributionSlider = withStyles({
  root: {
    display: 'block',
    // color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    // backgroundColor: 'transparent',
    // border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    display: 'none'
  },
  track: {
    height: 8,
    // borderColor: ui.colours.primaryLight2,
    backgroundColor: ui.colours.primaryLight2,
    border: `2px solid ${ui.colours.primaryLight2}`,
    borderRadius: 8,
  },
  rail: {
    height: 8,
    border: `2px solid ${ui.colours.primaryLight2}`,
    backgroundColor: ui.colours.background,
    borderRadius: 8,
    boxShadow: formBoxShadow,
  },
})(Slider);

const cell = {
  padding: `${ui.plannerStyles.expenseBreakdown.cellPaddingTop} 24px`,
  height: ui.plannerStyles.expenseBreakdown.cellHeight,
  color: ui.colours.foregroundDark2
};

const styles = theme => ({
  container: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    width: ui.plannerStyles.yearsContainer.left,
    height: ui.plannerStyles.summaryContainer.expandedHeight - ui.plannerStyles.summaryContainer.height,
    // padding: '0 15px',
    // borderRight: '3px solid rgba(255,255,255,0.2)',
    // boxSizing: 'border-box',
    color: ui.colours.foregroundDark,
    background: ui.colours.backgroundLight,
    boxShadow: `3px 0 5px 0px ${ui.colours.shadowColour}`,
    zIndex: 100
  },

  expenseLegend: {
    paddingTop: ui.plannerStyles.expenseBreakdown.paddingTop,
    height: ui.plannerStyles.expenseBreakdown.height,
    backgroundColor: ui.colours.background,
    borderBottom: `1px solid ${ui.colours.border}`
  },

  cell,

  cellOdd: {
    ...cell,
    backgroundColor: ui.colours.backgroundLight
  },

  configWrapper: {
    display: 'flex',
    height: '100%'
  },

  configContainer: {
    flexGrow: 1,
    padding: '24px',
    overflowX: 'hidden',
    overflowY: 'scroll'
  },

  legendContainer: {
    flexShrink: 0,
    width: 110,
    padding: '0 1em',
    fontWeight: 400,
    fontSize: 16,
    textAlign: 'right',
    backgroundColor: ui.colours.background
  },

  formInput: {
    display: 'block',
    margin: '1em 0 0.5em',
    padding: 12,
    width: '100%',
    color: ui.colours.primary,
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'center',
    backgroundColor: ui.colours.background,
    border: `2px solid ${ui.colours.primaryLight2}`,
    borderRadius: 4,
    boxShadow: formBoxShadow,

    '& input': {
      color: ui.colours.primary,
      maxWidth: 118,
      fontSize: 20,
      fontWeight: 400,
      border: 'none',
      background: 'none'
    }
  },

  // formInputAdornment: {
  //   color: 'rgba(255,255,255,0.7)'
  // }

  inflation: {
    display: 'block',
    marginTop: 5,
    color: ui.colours.primary,
  },

  editLink: {
    color: ui.colours.primary,
    textDecoration: 'underline',
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  },

  planButton: {
    display: 'block',
    width: '100%',
    marginBottom: '1em',
    textTransform: 'capitalize'
  },

  // title: {
  //   fontWeight: 500,
  //   fontSize: 16,
  //   color: '#fff'
  // },
});

class SummaryConfig extends Component {  
  state = {
    // Budget
    commencementDate: new Date(),
    startBalance: 0,
    units: 1,
    lots: 1,
    contributions: 0,
    inflation: 0,
    contributionInflation: 0,
    interest: 0,
    gstRegistered: false
  };

  componentDidMount() {
    this.setState(budgeter.budgetToLocal(this.props.budgetOptions));
  }

  updateValue = field => (event, value) => {
    const newValue = (event.target.value || value);

    this.setState({ [field]: newValue }, () => {
      if (newValue !== undefined) {
        this.props.updateBudget(budgeter.localToBudget(this.state));
      }
    });
  }

  updateCheckboxValue = field => (event, value) => {
    this.setState({ [field]: value },
      () => this.props.updateBudget(budgeter.localToBudget(this.state)));
  };

  updateDateValue = field => value => {
    this.setState({ [field]: value },
      () => this.props.updateBudget(budgeter.localToBudget(this.state)));
  }

  save() {
    const { activePropertyId } = this.props;

    budgeter.updateBudgetOptions(activePropertyId, budgeter.localToBudget(this.state));
  }

  getInputWidth(value) {
    return {
      width: 2 + (String(value || '').length + 1) * 12
    }
  }

  getSliderRange(forecast, contributionValue) {
    // Set range to 2x the max of debitTotal
    const highestDebit = Object.values(forecast.years)
      .map(({ creditTotal, debitTotal }) => debitTotal)
      // 200 is the min slider range (100 * 2)
      .reduce((value, total) => Math.ceil(Math.max(value, total)), 100) * 2;

    // Don't set lower than any overridden contribution amount
    return Math.max(highestDebit, contributionValue);
  }

  getSliderStep(forecast, contributionValue) {
    const maxValue = this.getSliderRange(forecast, contributionValue);

    // 100 Steps rounded to nearest 100
    return Math.ceil(maxValue / 100 / 100) * 100;
  }

  focusInput(value, event) {
    // Don't do any focus magic if clicking direct to the input
    if (event.target === this.refs.contributionInput)
      return;

    this.refs.contributionInput.focus();

    // Set carat to end
    this.refs.contributionInput.setSelectionRange(String(value || '').length * 2, String(value || '').length * 2)
  }

  render() {
    const { classes, history, forecast, activePropertyId } = this.props;
    const state = this.state;

    const domainValue = ui.getChartRange(forecast);

    const legend = [
      { prefix: '$', value: domainValue },
      { prefix: '$', value: Math.round(domainValue / 2) },
      { prefix: '$', value: 0 },
      { prefix: '- $', value: Math.round(domainValue / 2) },
      { prefix: '- $', value: domainValue },
    ];

    return (
      <div className={classes.container}>
        <div className={classes.expenseLegend}>
          <div className={classes.cell}>
            <span>Contributions</span>
          </div>
          <div className={classes.cellOdd}>
            <span>Expenses</span>
          </div>
          <div className={classes.cell}>
            <span>Interest</span>
          </div>
        </div>

        {/* Config Options */}
        <div className={classes.configWrapper}>
          <Grid container direction="column" justify="space-around" alignItems="stretch" className={classes.configContainer}>
            {/* Contributions */}
            <Grid item>
              <strong>Yearly contributions</strong>
              
              <form noValidate autoComplete="off">
                <div onClick={(event) => this.focusInput(state.contributions, event)} className={classes.formInput}>
                  <span>$</span>
                  <input
                    ref="contributionInput"
                    id="contributions"
                    value={state.contributions}
                    onChange={this.updateValue('contributions')}
                    style={this.getInputWidth(state.contributions)} />
                </div>

                <ContributionSlider
                  id="contributions"
                  value={Number(state.contributions)}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={this.getSliderStep(forecast, state.contributions)}
                  min={0}
                  max={this.getSliderRange(forecast, state.contributions)}
                  onChange={this.updateValue('contributions')} />
              </form>
            </Grid>

            {/* Inflation Rate */}
            <Grid item>
              <strong>Inflation rate:</strong>
              <strong className={classes.inflation}>{state.inflation}%</strong>
            </Grid>

            {/* Budget Inflation */}
            <Grid item>
              <strong>Budget inflation:</strong>
              <strong className={classes.inflation}>{state.contributionInflation}%</strong>
            </Grid>

            {/* Edit Property Details */}
            <Grid item>
              <Link color="primary" to={`/edit/${activePropertyId}?tab=financial`} className={classes.editLink}>
                <strong>Edit property details</strong>
              </Link>
            </Grid>

            {/* Buttons */}
            <Grid item>
              <Button variant="outlined" color="primary" size="large" className={classes.planButton} onClick={() => this.save()}>Save plan</Button>

              <Link color="primary" to={`/edit/${activePropertyId}?tab=report`}>
                <Button variant="outlined" color="primary" size="large" className={classes.planButton}>View report</Button>
              </Link>
            </Grid>
          </Grid>

          {/* Chart legend */}
          <Grid container direction="column" justify="space-around" alignItems="stretch" className={classes.legendContainer}>
            {legend.map(({ prefix, value }, index) =>
              <Grid key={index} item>
                <CurrencyFormat
                  value={value}
                  isNumericString={true}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                  prefix={prefix} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    )
  }
}
  
const mapStateToProps = (state) => ({
  activePropertyId: state.uiPlanning.activePropertyId,
  properties: state.property.properties,
  tasks: state.task.tasks,
  categories: state.budget.categories,
  budgetOptions: state.budget.options
});

const mapDispatchToProps = (dispatch) => ({
  updateBudget: debounce(budgetOptions => {
    dispatch({ type: 'BUDGET_UPDATE', budget: { options: budgetOptions } })
  }, 500)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(SummaryConfig));
