import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { routes } from '@hawthorn/services/config';
import { ui } from '@hawthorn/services/ui';
// import { auth } from '@hawthorn/services/firebase';

import NavigationAuth from './navigation-auth';
import NavigationProperties from './navigation-properties';
import NavigationPlanning from './navigation-planning';

const styles = theme => ({
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 100,
    color: 'white',
    boxShadow: '1px 1px 1px 0px rgba(0,0,0,0.2)'
    // boxShadow: 'none',
    // borderBottom: 'solid 1px #EB5E28'
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },

  logoWrapper: {
    display: 'block',
    width: 316,
    height: 64,
    marginLeft: -24,
    padding: '5px 100px 0',
    backgroundColor: '#fff',
    boxSizing: 'border-box'
  },

  logo: {
    height: 50
  },

  nav: {
    flex: 1
  }
});

class Nav extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;

    // Initial state
    this.state = {
      pathname: location.pathname
    };
  }

  componentDidMount() {
    // Listen for route changes and re-render so nav bar updates with route
    const { history } = this.props;

    history.listen((location, action) => this.setState({ pathname: location.pathname }));
  }

  getHeaderStyle(usePlanningHeader) {
    if (usePlanningHeader) {
      return {
        color: ui.colours.foregroundDark,
        backgroundColor: 'white'
      };
    }

    return { };
  }

  render() {
    const {
      authUser,
      classes
    } = this.props;

    const { pathname } = this.state;

    const usePlanningHeader = (pathname.startsWith('/planning') || pathname.startsWith('/edit'));

    return (
      <AppBar position="static" className={classes.header} style={this.getHeaderStyle(usePlanningHeader)}>
        <Toolbar>
          <div className={classes.toolbar}>
              { pathname === '/properties' &&
                <NavigationProperties className={classes.nav} />
              }

              { usePlanningHeader &&
                <NavigationPlanning className={classes.nav} />
              }

              { !!authUser && <NavigationAuth /> }
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}
 
const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(withRouter(Nav));
