import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'
import { withStyles } from "@material-ui/core/styles";
import { routes } from "@hawthorn/services/config";
import { auth } from "@hawthorn/services/auth";
import { users } from "@hawthorn/services/users";

const Register = ({ history, classes }) =>
  <Grid container spacing={24} style={{padding: 24}} justify="center">
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <RegisterForm history={history} classes={classes} />
    </Grid>
  </Grid>

const INITIAL_STATE = {
  name: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const styles = {
  input: {
    marginBottom: "1em",
    width: "100%"
  }
};

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      name,
      email,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.createUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        users.create(authUser.user.uid, name, email)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            history.push(routes.PROPERTIES);
          })
          .catch(error => {
            this.setState({ error: error });
          });
      })
      .catch(error => {
        this.setState({ error: error });
      });

    event.preventDefault();
  }

  render() {
    const {
      name,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const { classes } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      name === '';

    return (
      <Card>
        <CardContent>
          <form onSubmit={this.onSubmit}>
            <TextField
              className={classes.input}
              label="Name"
              value={name}
              type="text"
              variant="outlined"
              onChange={event => this.setState({ name: event.target.value })}
            />
            <TextField
              className={classes.input}
              label="Email Address"
              value={email}
              type="text"
              variant="outlined"
              onChange={event => this.setState({ email: event.target.value })}
            />
            <TextField
              className={classes.input}
              label="Password"
              value={passwordOne}
              type="password"
              variant="outlined"
              onChange={event => this.setState({ passwordOne: event.target.value })}
            />
            <TextField
              className={classes.input}
              label="Confirm Password"
              value={passwordTwo}
              type="password"
              variant="outlined"
              onChange={event => this.setState({ passwordTwo: event.target.value })}
            />

            { error && <p>{error.message}</p> }

            <CardActions>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button size="medium" color="primary" disabled={isInvalid} type="submit">Register</Button>
                </Grid>
              </Grid>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    );
  }
}

const RegisterLink = () =>
  <Typography align="center">
    <Link color="primary" to={routes.REGISTER}>Don't have an account? Register</Link>
  </Typography>

export default withStyles(styles)(withRouter(Register));

export {
  Register,
  RegisterLink,
};
