import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import { routes } from '@hawthorn/services/config';
import { auth } from '@hawthorn/services/auth';
import { register } from '@hawthorn/app/register';

const SignIn = ({ history, classes }) =>
  <Container maxWidth="sm">
    <SignInForm history={history} classes={classes} />
  </Container>

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const styles = {
  backgroundLogo: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url('/assets/logo-bg.svg') center center no-repeat`
  },

  loginCard: {
    position: 'relative',
    margin: '50px auto',
    width: '100%',
    maxWidth: 400,
    padding: '29px 39px',
    boxSizing: 'border-box',
    zIndex: 100
  },

  logo: {
    display: 'block',
    margin: '0 auto 2em',
    width: 170
  },

  input: {
    marginBottom: '1em',
    width: '100%'
  },

  divider: {
    margin: '1.5em 0 1em'
  }
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const { history } = this.props;

    auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.PROPERTIES);
      })
      .catch(error => {
        this.setState({ error: error });
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const { classes } = this.props;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <div>
        <div className={classes.backgroundLogo}></div>

        <Card className={classes.loginCard}>
          <CardContent>
            <img className={classes.logo} src="/assets/clearcost-logo.svg" alt="Clear Cost Logo" title="Clear Cost Maintenance Plans" />

            <form onSubmit={this.onSubmit}>
              <Typography gutterBottom><strong>Enter your email</strong></Typography>

              <TextField
                className={classes.input}
                placeholder="Email Address"
                value={email}
                onChange={event => this.setState({ email: event.target.value })}
                type="text"
                variant="outlined"
              />

              <Typography gutterBottom><strong>Enter your password</strong></Typography>

              <TextField
                className={classes.input}
                placeholder="Password"
                value={password}
                onChange={event => this.setState({ password: event.target.value })}
                type="password"
                variant="outlined"
              />

              { error && <p>{error.message}</p> }

              <Grid container direction="column" justify="stretch">
                <Button size="medium" variant="outlined" color="primary" disabled={isInvalid} type="submit">Sign In</Button>
                {/*
                <Divider className={classes.divider} />
                <register.RegisterLink />
                */}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(SignIn));

