import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import shortid from 'shortid';
import firebase from 'firebase/app';
import { firebase as fb } from '@hawthorn/services/firebase';

// Setup Filepond uploader
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImagePreview);

const styles = theme => ({

});

class ImageUpload extends Component {
  state = {
    files: [ ]
  };

  // To-do: move this to a service
  server = {
    // this uploads the image using firebase
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      // create a unique id for the file
      const id = shortid.generate()

      // upload the image to firebase
      const task = fb.storage.child(`${this.props.path}/${id}`).put(file, {
        contentType: 'image/jpeg',
      })

      // monitor the task to provide updates to FilePond
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snap => {
          // provide progress updates
          progress(true, snap.bytesTransferred, snap.totalBytes)
        },
        err => {
          // provide errors
          error(err.message)
        },
        () => {
          // the file has been uploaded
          load(id);

          this.props.complete(id);
        }
      )
    },

    // this loads an already uploaded image from firebase
    load: (source, load, error, progress, abort) => {
      // reset our progress
      progress(true, 0, 1024)

      // fetch the download URL from firebase
      fb.storage
        .child(`${this.props.path}/${source}`)
        .getDownloadURL()
        .then(url => {
          // fetch the actual image using the download URL
          // and provide the blob to FilePond using the load callback
          let xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function(event) {
            let blob = xhr.response
            load(blob)
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch(err => {
          error(err.message)
          abort()
        })
    }
  }

  render() {
    return (
      <FilePond
          files={this.state.files}
          allowMultiple={false}
          allowImageResize={true}
          allowImageTransform={true}
          imageResizeUpscale={false}
          imageResizeTargetWidth={500}
          maxFiles={1}
          server={this.server}
          onupdatefiles={fileItems => {
            // Set currently active file objects to this.state
            this.setState({
              files: fileItems.map(fileItem => fileItem.file)
            });
          }}
        />
    )
  }
}
 
const mapStateToProps = (state) => ({
  
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ImageUpload);
