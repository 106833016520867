import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { planner } from '@hawthorn/services/planner';
import { budgeter, reporting } from '@hawthorn/services/budgeter';
import propertiesEditStyles from './properties-edit-styles';
import SummaryChartGraph from '../planning/summary-panel/summary-chart/summary-chart-graph';

const styles = theme => ({
  ...propertiesEditStyles,

  chartWrapper: {
    height: 300,
    padding: 24
  }
});

class EditReport extends Component {
  unsubscribe = { };

  componentDidMount() {
    const { dispatch, match } = this.props;

    // Need to pull tasks if navigated direct to this screen
    this.unsubscribe.tasks = planner.getTasks(dispatch, match.params.id);
  }

  componentWillUnmount() {
    this.unsubscribe.tasks();
  }

  export(forecast, svgRef) {
    const { property, budgetOptions, categories, tasks } = this.props;

    // Pull SVG out of the DOM
    const chartSvg = svgRef && svgRef.current.querySelector('svg').outerHTML;

    reporting.generatePDF({
      property,
      budgetOptions,
      categories,
      tasks,
      forecast,
      chartSvg
    });
  }

  render() {
    const { classes, tasks, budgetOptions } = this.props;

    // Report should only ever do 10 years
    const NUM_YEARS = 10;    

    let limitedBudgetOptions = {
      ...budgetOptions,
      maxYears: NUM_YEARS
    };

    const forecast = budgeter.forecast(tasks, limitedBudgetOptions);
    const years = Object.keys(forecast.years).sort();

    const yearsData = years.map((year, index) => ({
      label: `Year ${index + 1}`,
      year,
      creditTotal: reporting.displayAmount(forecast.years[year].creditTotal),
      debitTotal: reporting.displayAmount(forecast.years[year].debitTotal),
      balance: reporting.displayAmount(forecast.years[year].balance)
    }));

    const svgRef = React.createRef();

    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h4" component="h3"><strong>Budget Summary</strong></Typography>
            </Grid>

            <Grid item>
              <Button variant="outlined" color="primary" size="large" onClick={() => this.export(forecast, svgRef)}>Export report</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table size="small" aria-label="Budget Summary">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">Year Ending</TableCell>
                <TableCell align="right">Yearly Contribution ($)</TableCell>
                <TableCell align="right">Estimated Expenditure ($)</TableCell>
                <TableCell align="right">Balance ($)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {yearsData.map(({ label, year, creditTotal, debitTotal, balance }) => (
                <TableRow
                  key={year}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{label}</TableCell>
                  <TableCell align="right">{year}</TableCell>
                  <TableCell align="right">{creditTotal}</TableCell>
                  <TableCell align="right">{debitTotal}</TableCell>
                  <TableCell align="right">{balance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
          
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" component="h3"><strong>Cash Flow Graph</strong></Typography>
        </Grid>

        <Grid itm x={12}>
          <div className={classes.chartWrapper} ref={svgRef}>
            <SummaryChartGraph forecast={forecast} condensed={true} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.task.tasks,
  categories: state.budget.categories,
  budgetOptions: state.budget.options
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(EditReport));
