import React from 'react';
import { connect } from 'react-redux';
import { firebase } from '@hawthorn/services/firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { setAuthUser } = this.props;

      firebase.auth.onAuthStateChanged(authUser => {
        // When no user, remove
        if (!authUser)
          return setAuthUser(null);

        // Pull user's claims
        firebase.auth.currentUser.getIdTokenResult(true) // 1
          .then(({ claims }) => setAuthUser({
            uid: claims.user_id,
            email: claims.email,
            admin: claims.admin ? claims.admin : false
          }));
      });
    }

    render() {
      return (
        <Component />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    setAuthUser: (authUser) => dispatch({ type: 'AUTH_USER_SET', authUser }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
}

export default withAuthentication;