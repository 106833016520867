import { db } from '@hawthorn/services/firebase/firebase';
import { BigNumber } from 'bignumber.js';

/*
  Process Flow:

  forecast()
    -> forecastTask() - for each task from options.startYear to options.maxYears (build up an index of when each task is due and how much)
    -> forecastYear() - for each year from options.startYear to options.maxYears
*/

export const defaultOptions = () => ({
  startYear: new Date().getFullYear() + 1,
  startBalance: new BigNumber(0),
  contributions: new BigNumber(0),
  interest: new BigNumber(0),
  inflation: new BigNumber(0),
  contributionInflation: new BigNumber(0),
  units: 1,
  lots: 1,
  maxYears: 15
});

// Deserialise and convert percentages to whole numbers
export const budgetToLocal = (budget) => {
  return {
    commencementDate: budget.commencementDate,
    startBalance: budget.startBalance,
    contributions: Number(budget.contributions),
    inflation: budget.inflation * 100,
    contributionInflation: budget.contributionInflation * 100,
    interest: budget.interest * 100,
    gstRegistered: budget.gstRegistered === undefined ? false : budget.gstRegistered
  };
}

// Serialise and convert percentage numbers to decimals
export const localToBudget = (localBudget) => {
  return {
    commencementDate: localBudget.commencementDate || new Date(),
    startBalance: localBudget.startBalance.toString(),
    contributions: localBudget.contributions,
    inflation: localBudget.inflation * 0.01,
    contributionInflation: localBudget.contributionInflation * 0.01,
    interest: localBudget.interest * 0.01,
    gstRegistered: localBudget.gstRegistered === undefined ? false : localBudget.gstRegistered
  };
}

export const updateBudgetOptions = (propertyId, options, budgetId = 'master') => {
  // return db.doc(`budgets/${id}`).set(budget, { merge: true });
  return db.doc(`properties/${propertyId}/budgets/${budgetId}`).set({ options }, { merge: true });
}

export const indexScheduled = (tasks, forecast) => {
  // Build an index for scheduled years per task
  let scheduledIndex = { };

  Object.keys(tasks)
    .filter(taskId => !tasks[taskId].archived)
    .forEach(taskId => {
      scheduledIndex[taskId] = { };

      Object.keys(forecast.years)
        .forEach(year => {
          // Find any scheduled for this task in this year
          scheduledIndex[taskId][year] =
            forecast.years[year].debit.filter(transaction => transaction.taskId === taskId)[0];
        });
    });

  return scheduledIndex;
};

/*
 * Build a forecast from all parameters, returns an object of:
 *
 * {
 *   years: {
 *     2019: {
 *       debit: [ ],
 *       credit: [ ],
 *       net: 0.00,
 *       balance: 0.00
 *     },
 *     2020: { ... }
 *   }
 * }
 */
export const forecast = (sTaskIndex, sOptions = { }) => {
  // Note: sTaskIndex and sOptions contain serialised numbers instead of BigNumber
  let options = Object.assign({ }, sOptions);

  // Convert to BigNumber
  [ 'startBalance', 'contributions', 'interest', 'inflation', 'contributionInflation' ]
    .forEach(key => {
      if (!sOptions[key])
        delete options[key];
      else
        options[key] = new BigNumber(sOptions[key])
    });

  options = Object.assign(defaultOptions(), options);

  // Convert taskIndex values to BigNumber
  let taskIndex = { };

  Object.keys(sTaskIndex)
    .filter(id => !sTaskIndex[id].archived)
    .forEach(id => {
      taskIndex[id] = Object.assign({ }, sTaskIndex[id]);
      taskIndex[id].cost = new BigNumber(taskIndex[id].cost);
      taskIndex[id].quantity = Number(taskIndex[id].quantity);
      taskIndex[id].lifeYears = Number(taskIndex[id].lifeYears);
      taskIndex[id].maintCost = new BigNumber(taskIndex[id].maintCost);
      taskIndex[id].maintFrequency = Number(taskIndex[id].maintFrequency);
    });

  let outlook = {
    years: { }
  };

  // Task forecast
  const forecastTaskIndex = forecastTasks(taskIndex, options);

  // Yearly forecast
  let currentYear = options.startYear;
  let yearsToEstimate = options.maxYears;
  let currentBalance = options.startBalance;

  while (yearsToEstimate > 0) {
    const yearForecastAmounts = forecastYear(currentYear, forecastTaskIndex, currentBalance, options);
    const yearBalance = calculateBalance(yearForecastAmounts, currentBalance);
    currentBalance = yearBalance.balance;

    // Combine results into output
    outlook.years[currentYear] = Object.assign({ }, yearForecastAmounts, yearBalance);

    currentYear++;
    yearsToEstimate--;
  }

  // Serialise outlook
  let sOutlook = {
    years: { }
  };

  Object.keys(outlook.years)
    .forEach(year => {
      sOutlook.years[year] = {
        debit: (outlook.years[year].debit || [ ]).map(serialiseTransaction),
        credit: (outlook.years[year].credit || [ ]).map(serialiseTransaction),
        
        debitTotal: display(outlook.years[year].debit
                      .reduce((total, transaction) => total.plus(transaction.amount), new BigNumber(0))),
        creditTotal: display(outlook.years[year].credit
                      .reduce((total, transaction) => total.plus(transaction.amount), new BigNumber(0))),

        net: display(outlook.years[year].net),
        balance: display(outlook.years[year].balance)
      }
    });

  return sOutlook;
};

export const serialiseTransaction = transaction => {
  return Object.assign({ }, transaction, { amount: display(transaction.amount) });
};

/*
 * Inputs the result of forecastYear() and calculates the net difference and remaining balance.
 *
 * {
 *   debit: [ ],
 *   credit: [ ],
 *   net: 0.00,
 *   balance: 0.00
 * }
 */
export const calculateBalance = (forecastAmounts, balance = new BigNumber(0)) => {
  let outlook = {
    net: new BigNumber(0),
    balance: balance
  };

  // Calculate balance
  for (let revenue of forecastAmounts.credit)
    outlook.net = outlook.net.plus(revenue.amount);

  for (let expense of forecastAmounts.debit)
    outlook.net = outlook.net.minus(expense.amount);

  outlook.balance = outlook.balance.plus(outlook.net);

  return outlook;
};

/*
 * Inputs the result of forecastTasks() and forecasts a single year, returns an object of:

 * {
 *   debit: [ ],
 *   credit: [ ]
 * }
 */
export const forecastYear = (year, forecastTaskIndex, balance = new BigNumber(0), options = { }) => {
  let outlook = {
    debit: [ ],
    credit: [ ]
  };

  // Handle expenses
  Object.keys(forecastTaskIndex)
    .filter(taskId => ((forecastTaskIndex[taskId].years || { })[year] || { }).due)
    .forEach(taskId =>
      outlook.debit.push({
        type: 'task',
        taskId: taskId,
        amount: forecastTaskIndex[taskId].years[year].cost,
        taskType: forecastTaskIndex[taskId].years[year].taskType
      }));

  // Handle contributions
  if (options.contributions && options.contributions.gt(0))
    outlook.credit.push({ type: 'contribution', amount: applyInflation(options.contributions, year, 'contribution', options) });

  // Handle interest
  if (options.interest && options.interest.gt(0) && balance.gt(0))
    outlook.credit.push({ type: 'interest', amount: options.interest.times(balance) });

  return outlook;
}

/*
 * Forecast all tasks across multiple years, returns an object of:
 *
 * {
 *   '001': {
 *     '2019': {
 *       due: true
 *       cost: 50.00
 *     },
 *     '2020': {
 *       due: false,
 *       cost: 0.00
 *     }
 *   },
 *   '002': { ... }
 * }
 */
export const forecastTasks = (taskIndex, options = { }) => {
  options = Object.assign(defaultOptions(), options);

  let outlook = { };

  for (let taskId in taskIndex)
    outlook[taskId] = forecastTask(taskIndex[taskId], options);

  return outlook;
};

/*
 * Forecast a task across multiple years, returns an object of:
 *
 * {
 *   years: {
 *     '2019': {
 *       due: true
 *       cost: 50.00
 *     },
 *     '2020': {
 *       due: false,
 *       cost: 0.00
 *     }
 *   }
 * }
 */
export const forecastTask = (task, options = { }) => {
  options = Object.assign(defaultOptions(), options);

  let outlook = {
    years: { }
  };

  let currentYear = options.startYear;
  let yearsToEstimate = options.maxYears;
  let replacementNextDue, maintenanceNextDue;

  while (yearsToEstimate > 0) {
    // Check replacement before maintenance
    const isReplacementDue = taskIsScheduled(currentYear, task.yearDue) || currentYear === replacementNextDue;
    const isMaintenanceDue = !isReplacementDue && (taskIsScheduled(currentYear, task.maintYearDue) || currentYear === maintenanceNextDue);

    let cost = 0;

    if (isReplacementDue)
      cost = calculateReplacementTaskCost(task, currentYear, options);
    else if (isMaintenanceDue)
      cost = calculateMaintenanceTaskCost(task, currentYear, options);

    // Only add to outlook if cost is greater than 0
    if (cost && cost.isGreaterThan(new BigNumber(0))) {
      outlook.years[currentYear] = {
        due: isReplacementDue || isMaintenanceDue,
        cost: cost
      };

      // Replacement also resets the maintenance life
      if (isReplacementDue) {
        outlook.years[currentYear].taskType = 'replacement';
        replacementNextDue = currentYear + task.lifeYears;

        if (task.maintCost && !task.maintCost.isNaN() && task.maintFrequency)
          maintenanceNextDue = currentYear + task.maintFrequency;
      } else if (isMaintenanceDue) {
        outlook.years[currentYear].taskType = 'maintenance';
        maintenanceNextDue = currentYear + task.maintFrequency;
      }
    } else {
      outlook.years[currentYear] = {
        due: false,
        cost: 0
      };
    }

    currentYear++;
    yearsToEstimate--;
  }

  return outlook;
};

export const taskIsScheduled = (year, yearDue) => {
  return (Number(yearDue) === year);
};

// Cost x quantity, increased by inflation
export const calculateReplacementTaskCost = (task, year, options) => {
  const baseCost = new BigNumber(task.cost).times(task.quantity);

  if (!options) {
    return baseCost;
  }

  return applyInflation(baseCost, year, 'expense', options);
};

export const calculateMaintenanceTaskCost = (task, year, options) => {
  return applyInflation(task.maintCost, year, 'expense', options);
};

/*
 * Formula taken form here: https://www.thecalculatorsite.com/articles/finance/compound-interest-formula.php
 *
 * Result = Amount(1 + Inflation) ^ Number of Years
 */
export const applyInflation = (amount, year, type, options) => {
  const inflation = type === 'expense' ? options.inflation : options.contributionInflation;

  if (!inflation)
    return amount;

  // When expense, start inflation in first year (1) vs second year (0)
  const numYears = year - options.startYear + (type === 'expense' ? 1 : 0);
  const inflatedAmount = amount.times(new BigNumber(inflation.plus(1)).pow(numYears));

  return inflatedAmount;
};

/*
 * Output as String formatted to 2 decimal places
 */
export const display = amount => {
  return amount.decimalPlaces(2).toFixed(2).toString();
};

