import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// Test
// const config = {
//   apiKey: 'AIzaSyCrdgB-iY5otAERJsD19DZpGjn0F3n0yfY',
//   authDomain: 'hawthorn-55b15.firebaseapp.com',
//   databaseURL: 'https://hawthorn-55b15.firebaseio.com',
//   projectId: 'hawthorn-55b15',
//   storageBucket: 'hawthorn-55b15.appspot.com',
//   messagingSenderId: '519058402985',
// };

const config = {
  apiKey: 'AIzaSyAep_w0IueOLnturH8UyLAvudsPwOHtiTE',
  authDomain: 'cboutlooks.firebaseapp.com',
  databaseURL: 'https://cboutlooks.firebaseio.com',
  projectId: 'cboutlooks',
  storageBucket: 'cboutlooks.appspot.com',
  messagingSenderId: '164945482827',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const storage = firebase.storage().ref();
const functions = firebase.app().functions('asia-northeast1');
const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });


export {
  auth,
  db,
  storage,
  functions
};