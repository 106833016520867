import { budgeter } from '@hawthorn/services/budgeter';
import { planner } from '@hawthorn/services/planner';

const INITIAL_CATEGORIES = planner.CATEGORIES
  .reduce((index, category) =>
    Object.assign(index, { [category.value]: { tasks: [ ] } }), { });

const INITIAL_STATE = Object.assign({ categories: INITIAL_CATEGORIES, options: budgeter.defaultOptions() });

const handlers = {
  RESET_PLANNING: state => ({
    ...INITIAL_STATE
  }),

  BUDGET_UPDATE: (state, { budget }) => ({
    ...state,
    categories: {
      ...INITIAL_STATE.categories,
      ...state.categories,
      ...budget.categories
    },
    options: {
      ...INITIAL_STATE.options,
      ...state.options,
      ...budget.options
    }
  }),

  CATEGORY_UPDATE: (state, { categoryId, tasks }) => ({
    ...state,
    categories: {
      ...state.categories,
      [categoryId]: {
        tasks
      }
    }
  }),

  CATEGORIES_UPDATE: (state, { categories }) => ({
    ...state,
    categories: {
      ...state.categories,
      ...categories
    }
  })
};

export default {
  initialState: INITIAL_STATE,
  handlers: handlers
};
