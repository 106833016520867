import { db } from '@hawthorn/services/firebase/firebase';

export const CATEGORIES = [
  { title: 'Uncategorised', value: 'none' },
  { title: 'Plant & Equipment', value: 'plant-equipment' },
  { title: 'Passenger Lifts', value: 'passenger-lifts' },
  { title: 'Heating & Cooling Systems', value: 'heating-cooling' },
  { title: 'Hot Water Systems', value: 'hot-water' },
  { title: 'Cold Water Loops', value: 'cold-water' },
  { title: 'Mechanical Ventilation', value: 'mechanical-ventilation' },
  { title: 'Security & Access', value: 'security-access' },
  { title: 'Hydraulics', value: 'hydraulics' },
  { title: 'Water Harvest & Irrigation', value: 'water-harvest-irrigation' },
  { title: 'Mains Infrastructure', value: 'mains-infrastructure' },
  { title: 'Major Essential Safety Measures', value: 'essential-safety-measures' },
  { title: 'Equipment', value: 'equipment' },
  { title: 'Pool Plant', value: 'pool-plant', divider: true },
  
  { title: 'External Façade', value: 'exterior' },
  { title: 'Roof', value: 'roof' },
  { title: 'External Common Grounds', value: 'external-common' },
  { title: 'Podium Level', value: 'podium-level', divider: true },
  
  { title: 'Main Lobby', value: 'main-lobby' },
  { title: 'Internal Common Areas', value: 'interior' },
  { title: 'Common Amenities', value: 'amenities' },
  { title: 'Car Park ', value: 'car-park' },
  { title: 'Basement Car Park ', value: 'basement', divider: true },

  { title: 'Compliance / Routine Tasks', value: 'compliance' },
  { title: 'Admin Budget', value: 'admin-budget' }
]

export const CONDITIONS = [
  { title: 'Good', value: 3 },
  { title: 'Fair', value: 2 },
  { title: 'Poor', value: 1 },
  { title: 'N/A', value: 4 },
  { title: 'Not Confirmed', value: 5 }
];

export const UNITS = [
  { title: 'no.', value: 'num' },
  { title: 'm2', value: 'm2' },
  { title: 'lm', value: 'lm' },
  { title: 'unit', value: 'unit' },
  { title: 'quote', value: 'quote' },
  { title: 'estimate', value: 'estimate' },
];

// Create initial fields
export const bootstrap = () => {
  db.doc(`categories/none`).set({ tasks: [ ] });
}

export const getBudget = (dispatch, propertyId, budgetId = 'master') => {
  return db.collection(`properties/${propertyId}/budgets`).doc(budgetId)
    .onSnapshot(documentSnapshot => {
      let budget = documentSnapshot.data() || { };
      budget.id = documentSnapshot.id;
      budget.options = budget.options || { };

      if (budget.updated)
        budget.updated = budget.updated.toDate();

      if (budget.options.commencementDate)
        budget.options.commencementDate = budget.options.commencementDate.toDate();

      dispatch({ type: 'BUDGET_UPDATE', budget });
    });
}

export const getTasks = (dispatch, propertyId) => {
  return db.collection(`properties/${propertyId}/tasks`)
    .onSnapshot(querySnapshot => {
      let docs = { };

      querySnapshot
        .forEach(doc => {
          // Filter out deleted
          if (!doc.data().archived)
            docs[doc.id] = doc.data();
        });

      dispatch({ type: 'TASKS_UPDATE', tasks: docs });
    });
}

export const addTask = (propertyId, task, categories, budgetId = 'master') => {
  return updateTask(propertyId, task)
          .then(() => prioritiseTasks(propertyId, categories, budgetId));
}

export const updateTask = (propertyId, task) => {
  return db.doc(`properties/${propertyId}/tasks/${task.id}`).set(task, { merge: true });
}

export const prioritiseTasks = (propertyId, categories, budgetId = 'master') => {
  return db.doc(`properties/${propertyId}/budgets/${budgetId}`).set({ categories }, { merge: true });
}
