import { db, functions } from '@hawthorn/services/firebase/firebase';

const sendInvite = functions.httpsCallable('sendInvite');

// User API
export const create = (id, name, email) => {
  return db.doc(`users/${id}`).set({
    name: name,
    email: email,
    created: new Date(),
    active: true
  });
}

export const get = id => {
  return db.collection('users').doc(id)
    .get()
    .then(doc => Promise.resolve(Object.assign({ id: doc.id }, doc.data())));
}

export const find = () => {
  return db.collection('users')
    .where('active', '==', true)
    .get()
    .then(querySnapshot => {
      let users = [ ];
      querySnapshot.forEach(doc => users.push({ id: doc.id, ...doc.data() }));

      return Promise.resolve(users);
    });
}

export const inviteUsers = (users) => {
  if (users.length === 0)
    return Promise.resolve([ ]);

  return sendInvite(users);
};
