import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import propertiesEditStyles from './properties-edit-styles';

const styles = theme => ({
  ...propertiesEditStyles 
});

class EditDescription extends Component {
  updateValue = (field, type) => (event, value) => {
    let newValue = (event.target.value || value || '');

    if (type === 'number') {
      newValue = newValue.length > 0 ? Number(newValue) : ''
    }

    this.props.onChange({ [field]: newValue });
  }

  render() {
    const { classes, property, authUser } = this.props;

    return (
      <Grid container direction="column" spacing={3}>
        {/* Inflations/Interest */}
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <label className={classes.label}>Property Address</label>

              <TextField
                value={property.address}
                onChange={this.updateValue('address')}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>Strata Number</label>
              
              <TextField
                value={property.strataNumber}
                onChange={this.updateValue('strataNumber')}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>Manager Name</label>
              
              <TextField
                value={property.managerName}
                onChange={this.updateValue('managerName')}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={12}>
              <label className={classes.label}>Number of Occupied Lots</label>
              
              <TextField
                type="number"
                value={property.lots}
                onChange={this.updateValue('lots', 'number')}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={12}>
              <label className={classes.label}>The Premise</label>
              
              <TextField
                value={property.premise}
                onChange={this.updateValue('premise')}
                variant="outlined"
                rows="4"
                multiline
                className={classes.input} />
            </Grid>

            <Grid item xs={12}>
              <label className={classes.label}>Site Description</label>
              
              <TextField
                value={property.description}
                onChange={this.updateValue('description')}
                variant="outlined"
                rows="4"
                multiline
                className={classes.input} />
            </Grid>

            <Grid item xs={12}>
              <label className={classes.label}>Defects / Known Issues / Notes</label>
              
              <TextField
                value={property.notes}
                onChange={this.updateValue('notes')}
                variant="outlined"
                rows="4"
                multiline
                className={classes.input} />
            </Grid>

            { authUser && authUser.admin &&
              <Grid item xs={12}>
                <label className={classes.label}>Inspection Details</label>
                
                <TextField
                  value={property.inspectionDetails}
                  onChange={this.updateValue('inspectionDetails')}
                  variant="outlined"
                  rows="4"
                  multiline
                  className={classes.input} />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles)
)(EditDescription);

