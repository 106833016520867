import { db } from '@hawthorn/services/firebase/firebase';

export const getProperties = (dispatch, authUser = { }) => {
  // Only active properties
  let query = db.collection('properties')
    .where('active', '==', true);

  // If not admin, only properties this user has access to
  if (!authUser.admin)
    query = query.where('users', 'array-contains', authUser.uid);

  return query
    .onSnapshot(querySnapshot => {
      let docs = { };

      querySnapshot
        .forEach(doc => docs[doc.id] = doc.data());

      dispatch({ type: 'PROPERTIES_UPDATE', properties: docs });
    });
}

export const getProperty = (dispatch, propertyId) => {
  return db.collection('properties').doc(propertyId)
    .onSnapshot(documentSnapshot => {
      let property = documentSnapshot.data() || { };

      dispatch({ type: 'PROPERTIES_UPDATE', properties: { [property.id]: property } });
    });
}

export const updateProperty = (property) => {
  property.updated = new Date();

  return db.doc(`properties/${property.id}`).set(property, { merge: true });
}
