import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import { firebase } from '@hawthorn/services/firebase';
import { ui } from '@hawthorn/services/ui';

const styles = theme => ({
  container: {
    position: 'sticky',
    left: 0,
    padding: 20,
    width: 320,
    zIndex: 100
  },

  card: {
    background: '#d1d1d1',
  },

  photoPreview: {
    objectFit: 'cover',
    height: 80
  },

  cardMask: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
    // background: 'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)'
  },

  cardTitle: {
    margin: 0,
    padding: '8px 10px',
    width: '100%',
    whiteSpace: 'pre-line',
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: 12,
    lineHeight: '1.2em',
    background: 'rgba(0,0,0,0.5)'
  }
});

class TaskCard extends Component {
  state = {
    photoUrl: null
  };

  componentDidMount() {
    const { task, activePropertyId } = this.props;

    // Get Photo URL
    if (task.photos && task.photos.length > 0) {
      firebase.storage.child(`properties/${activePropertyId}/tasks/${task.photos[0].key}`).getDownloadURL().then(url => {
        this.setState({ photoUrl: url });
      });
    }
  }

  getContainerStyle(odd) {
    return {
      background: odd ? ui.colours.background : 'transparent'
    };
  }

  render() {
    const {
      classes,
      task,
      editTask,
      odd
    } = this.props;

    const { photoUrl } = this.state;

    return (
      <div className={classes.container} style={this.getContainerStyle(odd)}>
        <Card className={classes.card}>
          <CardActionArea onClick={() => editTask(task)}>
            <CardMedia
              className={classes.photoPreview}
              image={photoUrl}
              title="Item Photo">
              <div className={classes.cardMask}>
                <Typography gutterBottom variant="subtitle1" className={classes.cardTitle}>
                  {task.title}
                </Typography>
              </div>
            </CardMedia>
          </CardActionArea>
        </Card>
      </div>
    )
  }
}
  
const mapStateToProps = (state) => ({
  activePropertyId: state.uiPlanning.activePropertyId
});

const mapDispatchToProps = (dispatch) => ({
  editTask: task => dispatch({ type: 'EDIT_TASK', task: task })
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaskCard);
