import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserSelect from './user-select';

const styles = theme => ({
  userEmail: {
    display: 'block',
    fontSize: 12
  },

  accessInput: {
    width: 120
  },

  userTableLastRow: {
    border: 'none'
  }
});


class EditAccess extends Component {
  userSelected(selectedUser) {
    let property = { ...this.props.property };
    let userIndex = { ...this.props.userIndex };

    property.users = property.users || [ ];

    // Only add if ID and email don't already exist
    if (!property.users.includes(selectedUser.id) && !property.users.find(userId => userIndex[userId] && userIndex[userId].email === selectedUser.email)) {
      userIndex[selectedUser.id] = selectedUser;
      property.users.push(selectedUser.id);

      this.props.onChange({ property, userIndex });
    }
  }

  remove(user) {
    // Remove from both users and access index
    let property = { ...this.props.property };
    
    property.users.splice(property.users.findIndex(userId => userId === user.id), 1);
    delete property.access[user.id];

    this.props.onChange({ property });
  }

  updateAccess(user, event) {
    let property = { ...this.props.property };
    property.access[user.id] = event.target.value;

    this.props.onChange({ property });
  }

  render() {
    const { classes, property, userIndex } = this.props;

    property.access = property.access || { };

    const users = ((property || { }).users || [ ])
      .filter(userId => (userIndex || { })[userId])
      .map(userId => Object.assign({ access: property.access[userId] || 'read' }, userIndex[userId]));

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="center">Access</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                <strong>{user.name}</strong>
                <span className={classes.userEmail}>{user.email}</span>
              </TableCell>
              <TableCell align="center">
                <FormControl className={classes.accessInput} variant="outlined">
                  <Select
                    size="small"
                    value={user.access}
                    onChange={event => this.updateAccess(user, event)}
                    input={<Input id="select-multiple-placeholder" />}>
                      <MenuItem value="owner">Owner</MenuItem>
                      <MenuItem value="read">Read-Only</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell align="right">
                <IconButton size="small" onClick={() => this.remove(user)}>
                  <FontAwesomeIcon icon="minus-circle" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} className={classes.userTableLastRow}>
              <UserSelect onChange={selected => this.userSelected(selected)} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default compose(
  withStyles(styles)
)(EditAccess);
