import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { firebase } from '@hawthorn/services/firebase';

const styles = theme => ({
  image: {
    width: '100%'
  }
});

class CdnImage extends Component {
  state = {
    path: null,
    src: null
  };

  componentDidMount() {
    const { path, defaultSrc } = this.props;

    if (!path)
      return this.setState({ src: defaultSrc, path });

    this.refreshImage();
  }

  componentDidUpdate() {
    if (this.state.path !== this.props.path)
      this.refreshImage();
  }

  refreshImage() {
    const { path } = this.props;

    firebase.storage.child(path).getDownloadURL()
      .then(src => this.setState({ src, path }));
  }

  render() {
    const { classes, alt, onClick } = this.props;
    const { src } = this.state;

    return (
      <div>
        { src && <img className={classes.image} src={src} alt={alt} onClick={onClick} /> }
      </div>
    )
  }
}

export default compose(
  withStyles(styles)
)(CdnImage);
