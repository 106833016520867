import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { routes } from '@hawthorn/services/config';
import { auth } from '@hawthorn/services/auth';
import { ui } from '@hawthorn/services/ui';

const styles = theme => ({
  navbar: {
    display: 'flex'
  },

  button: {
    height: 40,
    backgroundColor: ui.colours.backgroundLight2,
    borderColor: ui.colours.primary,

    '&:hover': {
      color: ui.colours.primaryDark,
      backgroundColor: ui.colours.background,
      borderColor: ui.colours.primaryDark
    }
  },

  logo: {
    marginLeft: 5,
    marginRight: 20,
    height: 28,

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  icon: {
    color: ui.colours.primary
  },

  menu: {
    width: 300,
    maxWidth: '100%'
  },

  menuSection: {
    padding: 20
  },

  menuDivider: {
    height: 1,
    backgroundColor: ui.colours.border,
    border: 'none'
  },

  menuLogo: {
    display: 'block',
    margin: '0 auto 10px',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 80
    }
  },

  menuLinks: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },

  menuButton: {
    marginTop: '1em',
    padding: 0,
    color: ui.colours.primary,
    fontWeight: 500,
    background: 'none',
    border: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: ui.colours.primaryDark
    }
  }
});

class NavigationAuth extends Component {
  state = {
    menuAnchor: null
  };

  signOut() {
    const { history } = this.props;

    this.closeMenu();

    auth.signOut()
      .then(() => history.push(routes.SIGN_IN));
  }

  openMenu(event) {
    this.setState({
      menuAnchor: event.currentTarget
    });
  }

  closeMenu() {
    this.setState({
      menuAnchor: null
    });
  }

  render() {
    const {
      classes
    } = this.props;

    const { menuAnchor } = this.state;

    return (
      <div className={classes.navbar}>
        <Button aria-label="Sign Out" variant="outlined" className={classes.button} onClick={(event) => this.openMenu(event)}>
          <img className={classes.logo} src="/assets/mabi-logo.png" alt="Mabi Services Logo" title="Mabi Services" />
          <FontAwesomeIcon className={classes.icon} icon="cog" size="lg" />
        </Button>

        <Popover
          className={classes.menuWrapper}
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={() => this.closeMenu()}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={classes.menu}>
            <div className={classes.menuSection}>
              <img className={classes.menuLogo} src="/assets/mabi-logo.png" alt="Mabi Services Logo" title="Mabi Services" />
              <strong>Mabi Services</strong>
            </div>

            <hr className={classes.menuDivider} />

            <div className={classes.menuSection}>
              <ul className={classes.menuLinks}>
                <li><Link color="primary" to="/properties" onClick={() => this.closeMenu()}>View all properties</Link></li>
                <li><button aria-label="Sign Out" className={classes.menuButton} color="primary" onClick={() => this.signOut()}>Sign out</button></li>
              </ul>
            </div>
          </div>
        </Popover>

        {/*<Button aria-label="Sign Out" variant="outlined" className={classes.button} onClick={() => this.signOut()}>
          <FontAwesomeIcon icon="sign-out-alt" size="lg" />
        </Button>*/}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  
});


export default compose(
  withStyles(styles),
  connect(() => ({ }), mapDispatchToProps)
)(withRouter(NavigationAuth));
