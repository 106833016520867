import { v4 } from 'uuid';

const INITIAL_STATE = {
  activePropertyId: null,
  showTaskDetail: false,
  isNewTask: false,

  editTask: {
    id: 'new-task',
    title: '',
    description: '',
    type: 'maintenance',
    quantity: '1',
    unit: 'num',
    cost: '',
    yearDue: '2019',
    lifeYears: ''
  }
};

const handlers = {
  SELECT_PROPERTY: (state, { propertyId }) => ({
    ...state,
    activePropertyId: propertyId
  }),

  NEW_TASK: (state) => ({
    ...state,
    editTask: {
      ...INITIAL_STATE.editTask,
      id: v4()
    },
    isNewTask: true,
    showTaskDetail: true
  }),

  EDIT_TASK: (state, { task }) => ({
    ...state,
    editTask: {
      ...INITIAL_STATE.editTask,
      ...task
    },
    isNewTask: false,
    showTaskDetail: true
  }),

  TOGGLE_DETAIL: (state, { value }) => ({
    ...state,
    showTaskDetail: value
  })
};

export default {
  initialState: INITIAL_STATE,
  handlers: handlers
};
