import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withAuthorisation from '@hawthorn/components/withAuthorisation';
import { routes, NEW_PROPERTY_ID } from '@hawthorn/services/config';
import { properties } from '@hawthorn/services/properties';
import { ui } from '@hawthorn/services/ui';
import PropertyCard from './property-card';

const styles = theme => ({
  page: {
    padding: '2em',
    width: '100%',
    minHeight: '100%',
    backgroundColor: ui.colours.background
  },

  header: {
    marginBottom: '2em',
    textAlign: 'center'
  },

  newPropertyButton: {
    margin: '2em auto 1em'
  }
});

class Properties extends Component {
  unsubscribe = { };

  componentDidMount() {
    const { dispatch, resetPlanning, authUser } = this.props;

    // Wipe planning state
    resetPlanning();

    this.unsubscribe.properties = properties.getProperties(dispatch, authUser);
  }

  componentWillUnmount() {
    this.unsubscribe.properties();
  }

  updateValue = field => event => {
    let newProperty = { ...this.state.newProperty };
    newProperty[field] = event.target.value;

    this.setState({ newProperty });
  }

  newProperty() {
    const { history } = this.props;
    
    history.push(routes.PROPERTIES_EDIT.replace(':id', NEW_PROPERTY_ID));
  }

  updatePhoto = key => {
    let newProperty = { ...this.state.newProperty };
    newProperty.photo = { key };

    this.setState({ newProperty });
  }

  render() {
    const {
      classes,
      properties
    } = this.props;

    const propertyList = Object.keys(properties).map(id => properties[id]);

    return (
      <div className={classes.page}>

        <div className={classes.header}>
          <Typography gutterBottom variant="h1">Properties</Typography>
          
          { propertyList.length > 0 && <Typography component="p">Select a property to continue, or</Typography> }
          { propertyList.length === 0 && <Typography component="p">You don't have any properties.</Typography> }

          <Button aria-label="Add New Property" color="primary" variant="outlined" className={classes.newPropertyButton} onClick={() => this.newProperty()}>
            Add New Property
          </Button>
        </div>

        {propertyList.map(property =>
          <PropertyCard key={property.id} property={property}></PropertyCard>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  properties: state.property.properties
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  resetPlanning: () => dispatch({ type: 'RESET_PLANNING' })
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorisation(authCondition),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Properties));
