import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { v4 } from 'uuid';
import { planner } from '@hawthorn/services/planner';

const styles = theme => ({

});



const TYPE_NUMBER = 'number';
const TYPE_COST = 'cost';
const TYPE_YEAR = 'year';

const FIELDS = [
  { key: 'title', label: 'Item/Title' },
  { key: 'description', label: 'Description', optional: true },
  { key: 'quantity', label: 'Quantity', optional: true, type: TYPE_NUMBER },
  { key: 'unit', label: 'Unit', optional: true },
  { key: 'category', label: 'Category', optional: true },
  { key: 'condition', label: 'Condition', optional: true },
  { key: 'cost', label: 'Replacement Cost ($)', optional: true, type: TYPE_COST },
  { key: 'yearDue', label: 'Replacement Next Due (Year)', optional: true, type: TYPE_YEAR },
  { key: 'lifeYears', label: 'Replacement Life (Years)', optional: true, type: TYPE_NUMBER },
  { key: 'maintCost', label: 'Maintenance Cost ($)', optional: true, type: TYPE_COST },
  { key: 'maintYearDue', label: 'Maintenance Next Due (Year)', optional: true, type: TYPE_YEAR },
  { key: 'maintFrequency', label: 'Maintenance Frequency (Years)', optional: true, type: TYPE_NUMBER }

];

class BulkImport extends Component {

  render() {
    const { property, categories } = this.props;

    return (
      <Importer
        chunkSize={10000}
        assumeNoHeaders={false}
        restartable={true}
        processChunk={async (rows, { startIndex }) => {
          // Sanitise data
          rows.forEach(row => {
            Object.keys(row)
              .forEach(key => row[key] = row[key].trim())

            // Remove any $ signs for cost
            FIELDS
              .filter(({ key, type }) => row[key])
              .forEach(({ key, type }) => {
                if (type === TYPE_COST) {
                  // When cost, strip $ signs
                  row[key] = row[key].replace(/\$/g, '');
                }
                else if (type === TYPE_NUMBER) {
                  // When number, convert to number
                  row[key] = Number(row[key]);

                  if (isNaN(row[key]))
                    delete row[key];
                }
                else if (type === TYPE_YEAR) {
                  row[key] = Number(row[key]);

                  if (isNaN(row[key]))
                    delete row[key];

                  // Year might be a hard-coded 4-digit year or relative offset
                  if (row[key] && row[key].length !== 4) {
                    row[key] = new Date().getFullYear() + row[key];
                  }
                }
                else if (key === 'unit') {
                  // Look-up units by lowercase and also accept partial matches
                  row[key] = row[key].toLowerCase();

                  row[key] = (planner.UNITS.find(({ title }) => title.includes(row[key])) || { }).value;
                }
                else if (key === 'condition') {
                  // Look-up condition
                  row[key] = (planner.CONDITIONS.find(({ title }) => title === row[key]) || { }).value;
                }

                if (!row[key])
                  delete row[key];

                if (row[key] && row[key].toString().length === 0)
                  delete row[key];
              });
          });

          for (let task of rows) {
            // Generate unique ID
            task.id = v4();

            // Look-up category by title or fallback to 'none'
            task.category = (planner.CATEGORIES.find(({ title }) => title === task.category) || planner.CATEGORIES[0]).value;
            categories[task.category].tasks.push(task.id);

            await planner.addTask(property.id, task, categories);
          }
        }}
      >
        {FIELDS.map(field =>
          <ImporterField key={field.key} name={field.key} label={field.label} optional={field.optional} />
        )}
      </Importer>
    );
  }
}

export default compose(
  withStyles(styles)
)(BulkImport);
