import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose';
import { v4 } from 'uuid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { firebase } from '@hawthorn/services/firebase';
import { properties } from '@hawthorn/services/properties';

const styles = theme => ({
  propertyTile: {
    maxWidth: 600,
    margin: '0 auto 3em',
    overflow: 'visible'
  },

  icon: {
    fontSize: 20,
    width: '20px !important'
  },

  propertyImage: {
    height: 220
  }
});

class PropertyCard extends Component {
  state = {
    property: {
      id: v4(),
      address: '',
      strataNumber: '',
      created: new Date(),
      active: true
    },
    menuAnchor: null,
    photoUrl: null
  };

  componentDidMount() {
    const { property } = this.props;

    this.setState(Object.assign({
      ...this.state,
      property: {
        ...this.state.property,
        ...property
      }
    }), () => this.refreshPhoto());
  }

  refreshPhoto() {
    const { property } = this.state;

    // Get Photo URL
    if (property.photo) {
      firebase.storage.child(`properties/${property.id}/${property.photo.key}`).getDownloadURL().then(url => {
        this.setState({ photoUrl: url });
      });
    } else {
      this.setState({ photoUrl: '/assets/sample-block.png' });
    }
  }

  openMenu(event) {
    this.setState({ menuAnchor: event.currentTarget });
  }

  closeMenu() {
    this.setState({ menuAnchor: null });
  }

  editProperty(property) {
    this.setState({ menuAnchor: null, editing: true });
  }

  deleteProperty(property) {
    this.setState({ menuAnchor: null });

    properties.updateProperty(Object.assign(property, { active: false, archived: new Date() }))
      .then(() => { });
  }

  render() {
    const {
      classes,
      history,
      authUser
    } = this.props;

    const {
      property,
      photoUrl,
      menuAnchor
    } = this.state;

    return (
      <div className={classes.page}>       
        <Card className={classes.propertyTile}>
          <CardHeader
            action={
              <div>
                { authUser && authUser.admin && (
                  <IconButton aria-controls="property-menu" aria-label="settings" onClick={event => this.openMenu(event)}>
                    <FontAwesomeIcon className={classes.icon} icon="ellipsis-v" />
                  </IconButton>
                ) }

                <Menu
                  id="property-menu"
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={() => this.closeMenu()}>
                  <MenuItem onClick={() => this.editProperty(property)}>Edit Property</MenuItem>
                  <MenuItem onClick={() => this.deleteProperty(property)}>Delete Property</MenuItem>
                </Menu>
              </div>
            }
            title={property.address}
            subheader={property.strataNumber} />

          <CardActionArea onClick={() => history.push('/planning/' + property.id)}>
            <CardMedia
              className={classes.propertyImage}
              image={photoUrl}
              title={property.address} />
            
            <CardContent>              
              <Typography variant="body2" color="textSecondary" component="p">{property.managerName}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  showNewProperty: state.uiProperties.showNewProperty
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch({ type: 'CANCEL_NEW_PROPERTY' })
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(PropertyCard));
