import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { omit } from 'lodash';
import { budgeter, reporting } from '@hawthorn/services/budgeter';
import { planner } from '@hawthorn/services/planner';
import ImageUpload from '@hawthorn/components/image-upload';
import CdnImage from '@hawthorn/components/cdn-image';

const ENTER_KEY_CODE = 13;

const styles = theme => ({
  columns: {
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },

  photoCrop: {
    maxHeight: '290px',
    overflow: 'hidden'
  },

  photoUpload: {
    width: '100%',
    backgroundColor: '#eee',
    cursor: 'pointer'
  },
});

class TaskDetail extends Component {
  state = {
    showUpload: false,
    photoPath: null,

    task: {
      title: '',
      description: '',
      quantity: 1,
      unit: '',
      category: 'none',
      condition: 2,
      cost: '',
      yearDue: '',
      lifeYears: '',
      maintCost: '',
      maintYearDue: '',
      maintFrequency: '',
      photos: [ ]
    },

    yearOptions: [ ]
  }

  componentDidMount() {
    const { editTask, activePropertyId, budgetOptions } = this.props;
    const task = Object.assign({ }, this.state.task, editTask);

    // Build years drop-down
    const yearOptions = [ ];

    for (let i = 0; i < 50; i++) {
      yearOptions.push({
        label: `Year ${i + 1} / ${budgetOptions.startYear + i}`,
        value: budgetOptions.startYear + i
      })
    }

    const getYearOption = year => yearOptions.find(yearOption => yearOption.value === year);

    // Task needs to be resolved to yearOption
    task.yearDue = getYearOption(task.yearDue) || '';
    task.maintYearDue = getYearOption(task.maintYearDue) || '';

    this.setState({ task, yearOptions });

    // Get Photo URL
    if (task.photos && task.photos.length > 0) {
      this.setState({ photoPath: `properties/${activePropertyId}/tasks/${task.photos[0].key}` });
    } else {
      this.setState({ showUpload: true })
    }

    this.keyDownListener = event => {
      // Save on Enter (but not if textarea selected or autocomplete)
      if (event.keyCode === ENTER_KEY_CODE && event.target.type !== 'textarea' && ![ 'yearDue', 'maintYearDue' ].includes(event.target.id))
        this.save(this.state.task);
    };

    document.getElementById('detailForm').addEventListener('keydown', this.keyDownListener, false);
  }

  componentWillUnmount() {
    document.getElementById('detailForm').removeEventListener('keydown', this.keyDownListener, false);
  }

  updateValue = field => event => {
    let task = { ...this.state.task };
    task[field] = event.target.value;

    this.setState({ task });
  }

  updateYearValue = field => (event, yearOption) => {
    let task = { ...this.state.task };
    task[field] = yearOption;

    this.setState({ task });
  }

  toggleUpload() {
    this.setState({ showUpload: true })
  }

  updatePhoto = key => {
    let task = { ...this.state.task };
    task.photos = [ { key } ];

    this.setState({ task });
  }

  delete(task) {
    // Remove from categories
    const { activePropertyId, categories, closeDetail } = this.props;

    // Remove from any categories
    Object.keys(categories)
      .map(id => categories[id])
      .forEach(category => {
        const index = category.tasks.indexOf(task.id);

        if (index > -1)
          category.tasks.splice(index, 1);
      });

    planner.prioritiseTasks(activePropertyId, categories)
      // Now mark as removed
      .then(() => planner.updateTask(activePropertyId, Object.assign(task, { archived: new Date() })))
      .then(() => closeDetail());
  }

  save(localTask) {
    const { isNewTask, closeDetail, activePropertyId, tasks, categories } = this.props;

    const task = Object.assign({ }, localTask, {
      yearDue: localTask.yearDue ? localTask.yearDue.value : null,
      maintYearDue: localTask.maintYearDue ? localTask.maintYearDue.value : null 
    })
   
    if (isNewTask) {
      categories[task.category].tasks.push(task.id);

      planner.addTask(activePropertyId, task, categories)
        .then(() => closeDetail());
    } else {
      planner.updateTask(activePropertyId, task)
        .then(() => {
          const oldCategory = tasks[task.id].category || 'none';
          const newCategory = task.category;

          // Check if category has changed
          if (newCategory === oldCategory)
            return Promise.resolve();

          const oldIndex = categories[oldCategory].tasks.indexOf(task.id);

          if (oldIndex > -1)
            categories[oldCategory].tasks.splice(oldIndex, 1);

          // Add to new category if not already
          if (categories[newCategory].tasks.indexOf(task.id) === -1)
            categories[newCategory].tasks.push(task.id);

          return planner.prioritiseTasks(activePropertyId, categories);
        })
        .then(() => closeDetail());
    }
  }

  render() {
    const {
      classes,
      closeDetail,
      activePropertyId
    } = this.props;

    const { task, showUpload, photoPath, yearOptions } = this.state;

    const replacementCost = reporting.displayAmount(budgeter.calculateReplacementTaskCost(task));

    // Insert dividers
    const categories = [ ];
    
    planner.CATEGORIES
      .forEach(category => {
        categories.push(omit(category, [ 'divider' ]));

        if (category.divider) {
          categories.push({ divider: true });
        }
      })

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography component="h3" variant="h5">Create New Item</Typography>
        </Grid>

        <Grid item>
          <form id="detailForm" noValidate autoComplete="off">
            <Grid container spacing={4} className={classes.columns} justify="space-evenly">
            {/* Photo Upload */}
              <Grid item xs>
                <Typography component="h4" variant="subtitle1" gutterBottom>Photos</Typography>

                { showUpload && <ImageUpload path={`properties/${activePropertyId}/tasks`} complete={this.updatePhoto}></ImageUpload> }
                { !showUpload &&
                  <div className={classes.photoCrop}>
                    <CdnImage onClick={() => this.toggleUpload()} className={classes.photoUpload} path={photoPath} alt={task.title}>></CdnImage>
                  </div>
                }
              </Grid>

            {/* Form Inputs Col 1 */}
              <Grid item xs>
                <Grid container spacing={1} direction="column">

                {/* Title */}
                  <Grid item>
                    <Typography component="h4" variant="subtitle1" gutterBottom>Item Details</Typography>

                    <TextField
                      fullWidth
                      id="title"
                      label="Title"
                      variant="filled"
                      className={classes.textField}
                      value={task.title}
                      onChange={this.updateValue('title')} />
                  </Grid>

                {/* Description */}
                  <Grid item>
                    <TextField
                      fullWidth
                      id="description"
                      label="Description"
                      multiline
                      rows="3"
                      variant="filled"
                      className={classes.textField}
                      value={task.description}
                      onChange={this.updateValue('description')} />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">

                    {/* Quantity */}
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          id="quantity"
                          label="Quantity"
                          type="number"
                          variant="filled"
                          className={classes.textField}
                          value={task.quantity}
                          onChange={this.updateValue('quantity')} />
                      </Grid>

                    {/* Unit */}
                      <Grid item xs={4}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="unit">Unit</InputLabel>

                          <Select
                            id="unit"
                            variant="filled"
                            value={task.unit}
                            inputProps={{
                              name: 'unit',
                              id: 'unit',
                            }}
                            onChange={this.updateValue('unit')} >
                              {planner.UNITS.map(unit =>
                                <MenuItem value={unit.value}>{unit.title}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1} direction="row">
                      {/* Category */}
                      <Grid item xs={6}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="category">Category</InputLabel>

                          <Select
                            id="category"
                            variant="filled"
                            value={task.category}
                            inputProps={{
                              name: 'category',
                              id: 'category',
                            }}
                            onChange={this.updateValue('category')} >
                              {categories.map(category =>
                                (!category.divider && <MenuItem key={category.value} value={category.value}>{category.title}</MenuItem>)
                                || (category.divider && <Divider />)
                              )}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* Condition */}
                      <Grid item xs={6}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel htmlFor="category">Condition</InputLabel>

                          <Select
                            id="condition"
                            variant="filled"
                            value={task.condition}
                            inputProps={{
                              name: 'condition',
                              id: 'condition',
                            }}
                            onChange={this.updateValue('condition')} >
                              {planner.CONDITIONS.map(condition =>
                                <MenuItem value={condition.value}>{condition.title}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </Grid>

                    </Grid>
                  </Grid>
                  
                </Grid>
              </Grid>

              {/* Form Inputs Col 2 */}
              <Grid item xs>
                <Grid container spacing={3} direction="column">
                  {/* Replacement */}
                  <Grid item>
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <Typography component="h4" variant="subtitle1" gutterBottom>Replacement</Typography>

                        {/* Cost */}
                        <FormControl fullWidth className={classes.margin}>
                          <TextField
                            id="cost"
                            variant="filled"
                            label="Cost Per Unit"
                            type="number"
                            helperText={(task.quantity > 1 && `$${task.cost} * ${task.quantity} (${task.unit}) = ${replacementCost}`)}
                            value={task.cost}
                            inputProps={{
                              step: '100'
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: (task.quantity > 1 && <InputAdornment position="end">{replacementCost}</InputAdornment>)
                            }}
                            onChange={this.updateValue('cost')} />
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <Grid container spacing={1} direction="row">
                        {/* Due */}
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Autocomplete
                                id="yearDue"
                                options={yearOptions}
                                getOptionLabel={option => option.label || ''}
                                value={task.yearDue}
                                onChange={this.updateYearValue('yearDue')}
                                renderInput={params => (
                                  <TextField {...params} label="Next Due" variant="filled" fullWidth />
                                )}
                              />
                            </FormControl>
                          </Grid>

                        {/* Life */}
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.margin}>
                              <TextField
                                id="life"
                                label="Total Life"
                                variant="filled"
                                value={task.lifeYears}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">years</InputAdornment>
                                }}
                                onChange={this.updateValue('lifeYears')} />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                {/* Maintenance */}
                  <Grid item>
                    <Grid container spacing={1} direction="column">
                      <Grid item>
                        <Typography component="h4" variant="subtitle1" gutterBottom>Maintenance</Typography>

                        {/* Cost */}
                        <FormControl fullWidth className={classes.margin}>
                          <TextField
                            id="maintCost"
                            variant="filled"
                            label="Cost"
                            type="number"
                            value={task.maintCost}
                            inputProps={{
                              step: '100'
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            onChange={this.updateValue('maintCost')} />
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <Grid container spacing={1} direction="row">
                        {/* Due */}
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                              <Autocomplete
                                  id="maintYearDue"
                                  options={yearOptions}
                                  getOptionLabel={option => option.label || ''}
                                  value={task.maintYearDue}
                                  onChange={this.updateYearValue('maintYearDue')}
                                  renderInput={params => (
                                    <TextField {...params} label="Next Due" variant="filled" fullWidth />
                                  )}
                                />
                            </FormControl>
                          </Grid>

                        {/* Frequency */}
                          <Grid item xs={6}>
                            <FormControl fullWidth className={classes.margin}>
                              <TextField
                                id="frequency"
                                variant="filled"
                                label="Frequency"
                                value={task.maintFrequency}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">years</InputAdornment>
                                }}
                                onChange={this.updateValue('maintFrequency')} />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item>
          <Divider variant="middle" />
        </Grid>

        <Grid item>
          <Grid container spacing={4} direction="row" justify="space-between">
            <Grid item>
              <Button size="large" onClick={() => this.delete(task)}>Delete Item</Button>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="flex-end" spacing={2}>
                <Grid item>
                  <Button size="large" onClick={() => closeDetail()}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" size="large" onClick={() => this.save(task)}>Save Item</Button>
                </Grid>
              </Grid>
             </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
  
const mapStateToProps = (state) => ({
  activePropertyId: state.uiPlanning.activePropertyId,
  isNewTask: state.uiPlanning.isNewTask,
  editTask: state.uiPlanning.editTask,
  tasks: state.task.tasks,
  categories: state.budget.categories,
  budgetOptions: state.budget.options
});

const mapDispatchToProps = (dispatch) => ({
  closeDetail: () => dispatch({ type: 'TOGGLE_DETAIL', value: false })
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaskDetail);
