import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ui } from '@hawthorn/services/ui';

const CcTabs = Tabs;

const CcTab = withStyles((theme) => ({
  root: {
    flex: 1,
    maxWidth: 'none',
    textTransform: 'none',
    fontSize: 16,
    color: ui.colours.foregroundDark2,
    borderBottom: '1px solid #979797'
  },
}))((props) => <Tab {...props} />);

const CcTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

export { CcTabs, CcTab, CcTabPanel };