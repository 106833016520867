const INITIAL_STATE = {
  users: [ { id: "test", name: "tester" } ],
};

const applySetUsers = (state, action) => ({
  ...state,
  users: action.users
});

function userReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case "USERS_SET": {
      return applySetUsers(state, action);
    }
    case "USERS_TEST": {
      return applySetUsers(state, action);
    }
    default : return state;
  }
}

export default userReducer;