export const colours = {
  // Note: this uses the backgroundLight colour
  backgroundFade: 'linear-gradient(90deg, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 80%)',
  background: '#f2f2f2',
  backgroundLight: '#f8f8f8',
  backgroundLight2: '#fff',
  foregroundLight: '#fff',
  foregroundDark: '#757575',
  foregroundDark2: '#333333',
  border: '#D7D7D7',

  primaryDark: '#0A60A0',
  primary: '#206dc9',
  primaryLight: '#1177D3',
  primaryLight2: '#49a3e4',

  negativeDark: '#c83a4b',
  negativeLight: '#ff6d7f',
  positive: '#70B603',
  negative: '#C83A4B',

  shadowColour: 'rgba(0,0,0,0.2)',
  emphasis: '#fd8e5c',
  hover: '#e8f4c0',
  drag: '#d5e4a1',
  
};

export const plannerStyles = {
  yearsContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    padding: 0,
    left: 317,
    listStyleType: 'none'
  },

  leftColumn: {
    width: 320
  },

  yearHeader: {
    height: 71
  },

  yearColumn: {
    width: 200,
    textAlign: 'center'
  },

  categoryHeader: {
    height: 74
  },

  taskRow: {
    height: 120
  },

  summaryContainer: {
    height: 50,
    expandedHeight: 660
  },

  expenseBreakdown: {
    paddingTop: 5,
    height: 108,
    cellHeight: 34,
    cellPaddingTop: '10px'
  }
};

export const getYearContainerWidth = (numYears, includeOffset = true) => {
  return (plannerStyles.yearColumn.width * numYears) + (includeOffset ? plannerStyles.yearsContainer.left : 0)
};

export const getChartRange = (forecast) => {
  const maxValue = Math.max(
    ...Object.values(forecast.years)
      .map(({ balance }) => Math.abs(balance))
  );

  // Round up to nearest even-number thousand (this is so our half-number chart point is still a thousand number)
  return Math.ceil(maxValue / 2000) * 2000;
};
