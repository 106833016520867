const INITIAL_STATE = {
  tasks: { },
};

const handlers = {
  RESET_PLANNING: state => ({
    ...INITIAL_STATE
  }),
  
  TASKS_SAVE: (state, { task }) => ({
    ...state,
    tasks: {
      ...state.tasks,
      [task.id]: task
    }
  }),

  TASKS_UPDATE: (state, { tasks }) => ({
    ...state,
    tasks
  })
};

export default {
  initialState: INITIAL_STATE,
  handlers: handlers
};
