import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ui } from '@hawthorn/services/ui';

const styles = theme => ({
  tile: {
    ...ui.plannerStyles.yearColumn,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },

  taskDetails: {
    position: 'relative',
    padding: '1em 0',
    width: '80%',
    textAlign: 'center',
    color: ui.colours.foregroundDark,
    fontWeight: 300,
    borderRadius: 8
  },

  icon: {
    marginRight: 10
  },

  typeLabel: {
    position: 'relative',
    top: -2
  },

  typeSubLabel: {
    position: 'relative',
    top: -2,
    left: 3,
    fontSize: 10
  },

  amount: {
    display: 'block',
    marginTop: 6,
    fontSize: 20
  }
});

class TaskForecast extends Component {
  getListStyle(isDraggingOver) {
    return {
      display: 'flex',
      // background: isDraggingOver ? ui.colours.drag : 'transparent',
      background: 'transparent',
      // width: isDraggingOver ? '' : '100%',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
    }
  }

  getItemStyle(isDragging, draggableStyle, index, snapshot) {
    return {
      ...ui.plannerStyles.yearColumn,

      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      // background: isDragging ? ui.colours.hover : 'transparent',
      background: 'transparent',
      // borderLeft: `${index === 0 ? 2 : 1}px solid rgba(0,0,0,0.1)`,
      // borderRight: '1px solid rgba(0,0,0,0.1)',
      boxSizing: 'border-box',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }

  getDummyCardStyle(isDraggingOver) {
    return {
      display: isDraggingOver ? 'block' : 'none',
      position: 'relative',
      width: '80%',
      height: 74,
      borderRadius: 8,
      backgroundColor: '#E1E1E1'
    }
  }

  getTaskColour(taskType) {
    return {
      maintenance: {
        color: ui.colours.primary
      },
      replacement: {
        color: ui.colours.negativeDark
      }
    }[taskType];
  }

  render() {
    const {
      classes,
      task,
      scheduled
    } = this.props;

    const years = Object.keys(scheduled).sort().map(yearId => Object.assign({ id: yearId }, scheduled[yearId]));

    return (
      <Droppable droppableId={task.id} type={task.id} key={task.id} direction="horizontal">
        {(provided, droppableSnapshot) => (
          <div ref={provided.innerRef} style={this.getListStyle(droppableSnapshot.isDraggingOver)}>
            {years
              .map((year, index) =>
                <Draggable
                  key={`${task.id}-${year.id}`}
                  draggableId={`${task.id}-${year.id}`}
                  isDragDisabled={!scheduled[year.id]}
                  index={index}>

                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      style={this.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        index,
                        snapshot
                      )}>

                      <div className={classes.tile}>
                      {/* Actual Item card */}
                        {scheduled[year.id] &&
                          <Card className={classes.taskDetails}>                
                            { scheduled[year.id].taskType === 'maintenance' &&
                              <div>
                                <FontAwesomeIcon className={classes.icon} style={this.getTaskColour(scheduled[year.id].taskType)} icon="tools" />
                                <span className={classes.typeLabel}>Repair</span> <span className={classes.typeSubLabel}>(Interim Cost)</span>
                              </div>
                            }
                            { scheduled[year.id].taskType === 'replacement' &&
                              <div>
                                <FontAwesomeIcon className={classes.icon} style={this.getTaskColour(scheduled[year.id].taskType)} icon="tag" />
                                <span className={classes.typeLabel}>Replace</span> <span className={classes.typeSubLabel}>(Full Cost)</span>
                              </div>
                            }

                            <CurrencyFormat
                              className={classes.amount}
                              style={this.getTaskColour(scheduled[year.id].taskType)}
                              value={scheduled[year.id].amount}
                              isNumericString={true}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={0}
                              prefix="$" />
                          </Card>
                        }

                      {/* Dummy placeholder card when dragging */}
                        {!scheduled[year.id] &&
                          <div  class="dummy-card" style={this.getDummyCardStyle(droppableSnapshot.isDraggingOver)}></div>
                        }
                      </div>
                    </div>
                  )}
                </Draggable>
              )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}
  
const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaskForecast);
