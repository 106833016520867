import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveContainer, AreaChart, Area, ReferenceArea, YAxis, XAxis, CartesianGrid, Tooltip } from 'recharts';
import { reporting } from '@hawthorn/services/budgeter';
import { ui } from '@hawthorn/services/ui';

const styles = theme => ({ });

const gradientOffset = (data) => {
  const dataMax = Math.max(...data.map((i) => i.balance));
  const dataMin = Math.min(...data.map((i) => i.balance));

  if (dataMax <= 0){
    return 0
  }
  else if (dataMin >= 0){
    return 1
  }
  else{
    return dataMax / (dataMax - dataMin);
  }
}

class SummaryChartGraph extends Component {
  formatTooltipLabel(years) {
    return (label) => label === 0 ? 'Starting' : `Year ${label} (${years[label - 1]})`
  }

  formatTooltip(value, name, props) {
    // Round values and format as currency
    value = reporting.displayAmount(Math.round(value));
    
    return [ value, 'Balance: ' ];
  }

  formatYLabel() {
    return (label) => reporting.displayAmount(Math.round(label))
  }

  formatXLabel() {
    return (label) => (label > 0 ? `Year ${label}` : '')
  }

  render() {
    const { forecast, budgetOptions, condensed, classes } = this.props;
    const years = Object.keys(forecast.years);

    const data = [
      { year: years[0] - 1, balance: budgetOptions.startBalance },
      
      ...years.map(year => ({
        year: year,
        balance: forecast.years[year].balance
      }))
    ];

    // Find the highest of min and max and use that as our domain range
    const domainValue = ui.getChartRange(forecast);

    const off = gradientOffset(data);

    return (
      <ResponsiveContainer>
        <AreaChart data={data} margin={{top: 0, right: 0, left: 0, bottom: 0}} stroke="none">
          {<CartesianGrid horizontal={false} />}
          <YAxis domain={[ -domainValue, domainValue ]} allowDataOverflow={true} hide={!condensed} tickFormatter={this.formatYLabel()} />
          <XAxis hide={!condensed} tickFormatter={this.formatXLabel()} />
          <Tooltip separator='' labelFormatter={this.formatTooltipLabel(years)} formatter={this.formatTooltip} />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor={ui.colours.positive} stopOpacity={1} />
              <stop offset={off} stopColor={ui.colours.negative} stopOpacity={1} />
            </linearGradient>
          </defs>
          <ReferenceArea x1={0} x2={data.length - 1} y1={0} y2={-domainValue} fill="#ececec" />
          <Area type="linear" dataKey="balance" stroke="url(#splitColor)" strokeWidth={6} fill="url(#splitColor)" />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}
  
const mapStateToProps = (state) => ({
  budgetOptions: state.budget.options
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SummaryChartGraph);
