import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { ui } from '@hawthorn/services/ui';
import SummaryChartGraph from './summary-chart-graph';

const cell = {
  padding: `${ui.plannerStyles.expenseBreakdown.cellPaddingTop} 22px`,
  height: ui.plannerStyles.expenseBreakdown.cellHeight,
  color: ui.colours.foregroundDark,
  fontWeight: 400,
  textAlign: 'right'
};

const styles = theme => ({
  years: {
    ...ui.plannerStyles.yearsContainer,
    height: ui.plannerStyles.expenseBreakdown.height,
    backgroundColor: ui.colours.background,
    borderBottom: `1px solid ${ui.colours.border}`,
    margin: 0,
    left: 0
  },

  year: {
    ...ui.plannerStyles.yearColumn,
    paddingTop: ui.plannerStyles.expenseBreakdown.paddingTop,
    boxSizing: 'border-box',
    borderRight: `1px solid ${ui.colours.border}`
  },

  cell,

  cellOdd: {
    ...cell,
    backgroundColor: ui.colours.backgroundLight
  },

  chartWrapper: {
    marginTop: ui.plannerStyles.expenseBreakdown.height,
    height: ui.plannerStyles.summaryContainer.expandedHeight - ui.plannerStyles.summaryContainer.height - ui.plannerStyles.expenseBreakdown.height
  }
});


class SummaryChart extends Component {  
  getContainerStyle(years) {
    return {
      position: 'absolute',
      top: ui.plannerStyles.summaryContainer.height,
      left: ui.plannerStyles.yearsContainer.left,
      width: ui.getYearContainerWidth(years.length, false),
      height: ui.plannerStyles.summaryContainer.expandedHeight - ui.plannerStyles.summaryContainer.height,
      background: ui.colours.backgroundLight,
      overflow: 'hidden'
    }
  }

  getBalanceStyle(yearForecast) {
    return {
      backgroundColor: (yearForecast.balance > -1 ? 'transparent' : ui.colours.negativeDark),
    }
  }

  getContributionAmount(yearForecast) {
    return yearForecast.credit.length > 0 ? yearForecast.credit[0].amount : 0;
  }

  getInterestAmount(yearForecast) {
    const interest = yearForecast.credit.find(({ type }) => type === 'interest');

    return interest ? interest.amount : 0;
  }

  render() {
    const { forecast, classes } = this.props;
    const years = Object.keys(forecast.years);

    return (
      <div style={this.getContainerStyle(years)}>
        <div>
          <ul className={classes.years}>
            {years.map(year =>
              <li key={year} className={classes.year}>
                {/* Contributions */}
                <div className={classes.cell}>
                  <CurrencyFormat
                    style={{ color: ui.colours.positive }}
                    value={this.getContributionAmount(forecast.years[year])}
                    isNumericString={true}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix={'+$'} />
                </div>

                {/* Expenses */}
                <div className={classes.cellOdd}>
                  <CurrencyFormat
                    style={{ color: ui.colours.negative }}
                    value={forecast.years[year].debitTotal * -1}
                    isNumericString={true}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix={'$'} />
                </div>

                {/* Interest */}
                <div className={classes.cell}>
                  <CurrencyFormat
                    value={this.getInterestAmount(forecast.years[year])}
                    isNumericString={true}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix={'+$'} />
                </div>
              </li>
            )}
          </ul>
        </div>

        <div className={classes.chartWrapper}>
          <SummaryChartGraph forecast={forecast} />
        </div>
      </div>
    )
  }
}
  
const mapStateToProps = (state) => ({
  budgetOptions: state.budget.options
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SummaryChart);
