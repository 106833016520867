export default {
  label: {
    display: 'block',
    fontWeight: 500
  },

  input: {
    marginTop: 8,
    width: '100%',

    '& input': {
      padding: '1em'
    }
  }
};