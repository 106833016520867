import { combineReducers } from 'redux';
import sessionReducer from './session';
import uiProperties from './ui-properties';
import uiPlanning from './ui-planning';
import userReducer from './user';
import property from './property';
import task from './task';
import budget from './budget';

function createReducer({ initialState, handlers }) {
	return (state = initialState, action) => {
		if (handlers.hasOwnProperty(action.type))
			return handlers[action.type](state, action);

		return state;
	};
}

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  uiProperties: createReducer(uiProperties),
  uiPlanning: createReducer(uiPlanning),
  userState: userReducer,
  property: createReducer(property),
  task: createReducer(task),
  budget: createReducer(budget)
});

export default rootReducer;
