import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withAuthorisation from "@hawthorn/components/withAuthorisation";
import { properties } from '@hawthorn/services/properties';
import { planner } from '@hawthorn/services/planner';
import { budgeter } from '@hawthorn/services/budgeter';
import { ui } from '@hawthorn/services/ui';
import SummaryPanel from './summary-panel';
import TaskDetail from './task-detail';
import TaskRow from './task-row';

const grid = 8;
const rowHeight = 115;

const boardBottomMargin = (ui.plannerStyles.summaryContainer.height + 50);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 280,
  },
  control: {
    padding: theme.spacing(2),
  },

  board: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '64px',
    bottom: 0,
    paddingBottom: boardBottomMargin,
    background: ui.colours.backgroundLight,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    scrollBehavior: 'smooth'
  },

  fadeOut: {
    position: 'absolute',
    right: 0,
    width: ui.plannerStyles.yearColumn.width * 5,
    height: '100%',
    padding: 20,
    background: ui.colours.backgroundFade,
    zIndex: 100
  },

  loadMoreButton: {
    display: 'block',
    float: 'right',
    height: '100%',
    padding: 20,
    background: 'none',
    border: `2px solid ${ui.colours.border}`,
    textTransform: 'uppercase',
    borderRadius: 8
  },

  loadMoreButtonIcon: {
    fontSize: 30
  },

  leftColumnBackground: {
    position: 'fixed',
    left: 0,
    // (64 is height of the nav header)
    top: ui.plannerStyles.yearHeader.height + 64,
    width: ui.plannerStyles.leftColumn.width,
    height: '100%',
    background: ui.colours.backgroundLight2,
    boxShadow: `3px 0 5px 0px ${ui.colours.shadowColour}`,
    zIndex: 20
  },

  gridLineContainer: {
    display: 'flex',
    alignItems: 'stretch',
    position: 'absolute',
    left: ui.plannerStyles.leftColumn.width,
    top: ui.plannerStyles.yearHeader.height,
    height: '100%',
    minHeight: `calc(100% + ${boardBottomMargin}px)`
  },

  gridLine: {
    width: ui.plannerStyles.yearColumn.width,
    borderRight: '1px solid #d7d7d7'
  },

  header: {
    position: 'sticky',
    top: 0,
    height: ui.plannerStyles.yearHeader.height,
    background: ui.colours.backgroundLight2,
    borderTop: '1px solid #d7d7d7',
    borderBottom: '1px solid #d7d7d7',
    zIndex: 200
  },

  addNewItem: {
    margin: 'auto 20px',
    width: 277
  },

  years: {
    ...ui.plannerStyles.yearsContainer,
    margin: 0,
    bottom: 0,
    boxShadow: `0 9px 5px -6px ${ui.colours.shadowColour}`,
  },

  year: {
    ...ui.plannerStyles.yearColumn
  },

  yearTitle: {
    color: '#777',
    padding: '0 22px',
    textAlign: 'right',
    textTransform: 'uppercase',
    fontWeight: 400,
  },

  yearSubTitle: {
    display: 'block',
    fontSize: 12,
    marginBottom: -6
  },

  contentWrapper: {
  },

  emptyState: {
    margin: '100px 20px',
    textAlign: 'center'
  },

  column: {
    display: 'inline-block',
    width: '300px',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto'
  },

  columnInner: {
    display: 'flex',
    marginBottom: '2em',
    flexDirection: 'column',
    alignItems: 'center'
  },

  categoryWrapper: {
    minHeight: rowHeight
  },

  categoryHeader: {
    width: ui.plannerStyles.leftColumn.width,
    height: ui.plannerStyles.categoryHeader.height,
    background: ui.colours.backgroundLight
  },

  categoryTitle: {
    position: 'sticky',
    left: 40,
    margin: '32px 14px 0',
    color: '#777',
    fontWeight: 400,
    zIndex: 100
  },

  taskRow: {
    height: ui.plannerStyles.taskRow.height
  },

  taskDrawer: {
    padding: '2em'
  },
});

class Planning extends Component {
  unsubscribe = { };
  boardRef = null;

  componentDidMount() {
    const {
      dispatch,
      match,
      selectProperty
    } = this.props;

    selectProperty(match.params.id);

    this.unsubscribe.property = properties.getProperty(dispatch, match.params.id);
    this.unsubscribe.budget = planner.getBudget(dispatch, match.params.id);
    this.unsubscribe.tasks = planner.getTasks(dispatch, match.params.id);
  }

  componentWillUnmount() {
    this.unsubscribe.property();
    this.unsubscribe.budget();
    this.unsubscribe.tasks();
  }

  getHeaderStyle(years) {
    return {
      width: ui.getYearContainerWidth(years.length)
    }
  }

  getGridLineContainerStyle(categories, tasks, scheduledIndex) {
    return {
      height: planner.CATEGORIES
        .filter(category => categories[category.value].tasks.length > 0)
        .reduce((height, category) => {
          const numTasks = categories[category.value].tasks
              .filter(taskId => tasks[taskId] && scheduledIndex[taskId])
              .length;

          // Add category height + numTasks * taskHeight
          return height + ui.plannerStyles.categoryHeader.height + (numTasks * ui.plannerStyles.taskRow.height);
        }, 50)
    }
  }

  getItemStyle(isDragging, draggableStyle, index) {
    // Odd/even background colour
    const backgroundColour = index % 2 === 0 ? ui.colours.background : 'transparent';

    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      // padding: grid * 2,

      // change background colour if dragging
      // background: isDragging ? ui.colours.hover : backgroundColour,
      background: backgroundColour,

      // styles we need to apply on draggables
      ...draggableStyle
    };
  }

  getListStyle(years, isDraggingOver) {
    return {
      // background: isDraggingOver ? ui.colours.drag : 'transparent',
      background: 'transparent',
      paddingBottom: isDraggingOver ? rowHeight : 0,
      boxSizing: 'border-box',
      width: ui.getYearContainerWidth(years.length)
    }
  }

  getList(id) {
    return this.state[this.id2List[id]];
  }

  onDragEnd = result => {
    const { source, destination, draggableId } = result;
    const { categories, tasks, scheduledIndex, saveTask, updateCategory, updateCategories, match } = this.props;

    const activePropertyId = match.params.id;

    if (tasks[source.droppableId])
      return this.moveYearDue(tasks[source.droppableId], scheduledIndex, source.index, destination.index);

    if (!destination || !tasks[draggableId])
      return;

    if (source.droppableId === destination.droppableId) {
      // Re-order
      const tasks = this.reorder(categories[source.droppableId].tasks, source.index, destination.index);
      categories[source.droppableId].tasks = tasks;

      // Update single year
      updateCategory(source.droppableId, tasks);

      return planner.prioritiseTasks(activePropertyId, categories);
    } else {
      const taskId = result.draggableId;

      // Move in categories
      const { newSource, newDestination } = this.move(
          categories[source.droppableId].tasks,
          categories[destination.droppableId].tasks,
          source,
          destination);

      categories[source.droppableId].tasks = newSource;
      categories[destination.droppableId].tasks = newDestination;

      // Update in task
      tasks[taskId].category = destination.droppableId;

      saveTask(tasks[taskId]);
      updateCategories(categories);

      return planner.updateTask(activePropertyId, tasks[taskId])
        .then(() => planner.prioritiseTasks(activePropertyId, categories));
    }
  };

  moveYearDue(task, scheduledIndex, startIndex, endIndex) {
    const { saveTask, match } = this.props;
    const activePropertyId = match.params.id;

    // Check if maintenance or replacement moved
    let transaction = Object.keys(scheduledIndex[task.id])
                        .sort()
                        .map(year => scheduledIndex[task.id][year])[startIndex];

    if (!transaction)
      return;

    // Calculate number of years moved
    const diffYears = endIndex - startIndex;

    if (transaction.taskType === 'replacement')
      task.yearDue = Number(task.yearDue) + diffYears;
    else if (transaction.taskType === 'maintenance')
      task.maintYearDue = Number(task.maintYearDue) + diffYears;

    saveTask(task);
    planner.updateTask(activePropertyId, task);
  };

  reorder(tasks, startIndex, endIndex) {
    const result = Array.from(tasks);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [ removed ] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    return { newSource: sourceClone, newDestination: destClone };
  }

  loadMore(maxYears, ref) {
    const { changeYears } = this.props;

    changeYears(maxYears);

    // Wait for years to update then scroll
    setTimeout(() => {
      ref.scrollTo(ref.scrollLeft + (ui.plannerStyles.yearColumn.width * 5), ref.scrollTop)
    }, 1)
  }

  closeDrawer(event) {
    const { closeTask } = this.props;

    // Only close on keydown (not click away)
    if (event.type === 'keydown')
      closeTask();
  }

  render() {
    const {
      classes,
      ui,
      tasks,
      categories,
      forecast,
      scheduledIndex,
      createTask
    } = this.props;

    const emptyState = Object.keys(tasks || { }).length === 0;

    // Pad-out years with forecast    
    const years = Object.keys(forecast.years);

    return (
      <div id="planning-board" className={classes.board} ref={(ref) => this.boardRef = ref}>
        <Drawer
          anchor="bottom"
          open={ui.showTaskDetail}
          onClose={event => this.closeDrawer(event)}>
            <div className={classes.taskDrawer}>
              <TaskDetail />
            </div>
        </Drawer>

        {/* Floating Left Column (just for aesthetics) */}
        <div className={classes.leftColumnBackground}>

        </div>

        {/* Grid Lines */}
        <div className={classes.gridLineContainer} style={this.getGridLineContainerStyle(categories, tasks, scheduledIndex)}>
          {years.map((year, index) =>
            <div key={year} className={classes.gridLine}></div>
          )}

          <div className={classes.fadeOut}>
            <Button className={classes.loadMoreButton} onClick={() => this.loadMore(years.length + 15, this.boardRef)}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                  <span>Keep Going</span>
                </Grid>

                <Grid item>
                  <FontAwesomeIcon
                    className={classes.loadMoreButtonIcon}
                    icon="chevron-right" />
                </Grid>
              </Grid>
            </Button>
          </div>
        </div>


        {/*<pre>{JSON.stringify(forecast, null, 2)}</pre>*/}

        {/*<pre>
          <div>1 {JSON.stringify(tasks, null, 2)}</div>
          <div>2 {JSON.stringify(years, null, 2)}</div>
        </pre>*/}

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.header} style={this.getHeaderStyle(years)}>
            <Grid item>
              <Button aria-label="Add New Item" color="primary" variant="outlined" className={classes.addNewItem} onClick={() => createTask()}>
                Add New Item
              </Button>
            </Grid>

            <Grid item className={classes.yearsWrapper}>
              <ul className={classes.years}>
                {years.map((year, index) =>
                  <li key={year} className={classes.year}>
                    <Typography gutterBottom variant="h6" component="h2" className={classes.yearTitle}>
                      <span className={classes.yearSubTitle}>Year {index + 1}</span>
                      <span>{year}</span>
                    </Typography>
                  </li>
                )}
              </ul>
            </Grid>
          </Grid>

          <div className={classes.contentWrapper}>
            {emptyState && 
              <div className={classes.emptyState}>
                 <p>This property doesn't have any tasks. Click <strong>Add New Item</strong> at the top of the screen to begin!</p>
              </div>
            }

            {planner.CATEGORIES
              .filter(category => categories[category.value].tasks.length > 0)
              .map(category =>
                <div key={category.value} className={classes.categoryWrapper}>
                  <Grid container className={classes.categoryHeader} style={this.getHeaderStyle(years)}>
                    <Typography gutterBottom variant="h6" component="h2" className={classes.categoryTitle}>{category.title}</Typography>
                  </Grid>

                  <Droppable droppableId={category.value} type="categories" key={category.value}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={this.getListStyle(years, snapshot.isDraggingOver)}>
                        {categories[category.value].tasks
                          .filter(taskId => tasks[taskId] && scheduledIndex[taskId])
                          .map(taskId => tasks[taskId])
                          .map((task, index) =>
                            <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}>

                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      index
                                    )}>
                                      <TaskRow className={classes.taskRow} task={task} scheduled={scheduledIndex[task.id]} odd={index % 2 === 0}></TaskRow>
                                  </div>
                                )}
                            </Draggable>
                          )}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </div>
        </DragDropContext>

        <SummaryPanel forecast={forecast}></SummaryPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const forecast = budgeter.forecast(state.task.tasks, state.budget.options);

  return {
    ui: state.uiPlanning,
    categories: state.budget.categories,
    tasks: state.task.tasks,
    forecast: forecast,
    scheduledIndex: budgeter.indexScheduled(state.task.tasks, forecast)
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  selectProperty: propertyId => dispatch({ type: 'SELECT_PROPERTY', propertyId }),
  // propertyChange: property => dispatch({ type: 'PROPERTY_CHANGE', property }),

  saveTask: task => dispatch({ type: 'TASKS_SAVE', task }),
  updateTasks: tasks => dispatch({ type: 'TASKS_UPDATE', tasks }),
  updateCategory: (categoryId, tasks) => dispatch({ type: 'CATEGORY_UPDATE', categoryId, tasks }),
  updateCategories: categories => dispatch({ type: 'CATEGORIES_UPDATE', categories }),
  changeYears: maxYears => dispatch({ type: 'BUDGET_UPDATE', budget: { options: { maxYears } } }),

  createTask: () => dispatch({ type: 'NEW_TASK' }),
  closeTask: () => dispatch({ type: 'TOGGLE_DETAIL', value: false })
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorisation(authCondition),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Planning));
