import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import { firebase } from '@hawthorn/services/firebase';
import { routes } from "@hawthorn/services/config";
import { ui } from '@hawthorn/services/ui';

const YEAR_VALUES = [ 10, 15, 25, 50 ];

const foregroundColour = '#555555'

const styles = theme => ({
  navbarWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },

  navbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  propertySelection: {
    display: 'flex'
  },

  propertyAvatar: {
    marginRight: 10,
    width: 75,
    height: 40,
    borderRadius: 0
  },

  propertyTitle: {
    fontSize: 16,
    fontWeight: 500,
    margin: 'auto 0',
    color: foregroundColour
  },

  descriptionContainer: {
    color: foregroundColour,
    fontSize: 12,
    fontWeight: 300
  },

  propertyDescription: {
    marginRight: '0.5em'
  },

  editLink: {
    textDecoration: 'underline'
  },

  yearButton: {
    color: foregroundColour,
    borderRadius: 0
  }
});

class NavigationPlanning extends Component {
  state = {
    photoUrl: null
  };

  componentDidMount() {
    const { properties, activePropertyId } = this.props;
    const property = properties[activePropertyId];

    // Get Photo URL
    if (property && property.photo) {
      firebase.storage.child(`properties/${property.id}/${property.photo.key}`).getDownloadURL().then(url => {
        this.setState({ photoUrl: url });
      });
    } else {
      this.setState({ photoUrl: '/assets/sample-block.png' });
    }
  }

  getYearButtonStyle(year, selectedYear) {
    if (year === selectedYear)
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.23)'
      };

    return { };
  }

  render() {
    const {
      classes,
      changeYears,
      activePropertyId,
      properties,
      selectedYear,
      history
    } = this.props;

    const { photoUrl } = this.state;

    const property = properties[activePropertyId];
    
    let description = '';

    if (property) {
      description = [
        property.lots ? `${property.lots} lot${property.lots > 1 ? 's' : ''}` : null
      ].filter(val => val).join(', ');
    }

    return (
      <div className={classes.navbarWrapper}>
        { !!property &&
          <div className={classes.navbar}>
            <div className={classes.propertySelection}>
              <Avatar alt={property.address} src={photoUrl} className={classes.propertyAvatar} />
              <div>
                <Typography gutterBottom variant="h5" component="h2" className={classes.propertyTitle}>{property.address}</Typography>
                <Typography gutterBottom variant="body2" component="p" className={classes.descriptionContainer}>
                  <span className={classes.propertyDescription}>{description}</span>
                  <Link color="primary" to={`/edit/${property.id}`} className={classes.editLink}>Edit Details</Link>
                </Typography>
               </div>
            </div>

            {/*<div>
              {YEAR_VALUES
                .map(year =>
                  <Button
                    key={year}
                    aria-label={'View ' + {year} + ' Year Forecast'}
                    variant="outlined"
                    className={classes.yearButton}
                    style={this.getYearButtonStyle(year, selectedYear)}
                    onClick={() => changeYears(year)}>{year}</Button>
              )}
            </div>*/}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  activePropertyId: state.uiPlanning.activePropertyId,
  properties: state.property.properties,
  selectedYear: state.budget.options.maxYears
});

const mapDispatchToProps = (dispatch) => ({
  changeYears: maxYears => dispatch({ type: 'BUDGET_UPDATE', budget: { options: { maxYears } } }),
});

export default compose(
  withStyles(styles),
  connect(() => mapStateToProps, mapDispatchToProps)
)(withRouter(NavigationPlanning));
