import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEqual } from 'lodash';
import * as qs from 'querystring';
import { v4 } from 'uuid';
import withAuthorisation from '@hawthorn/components/withAuthorisation';
import { routes, NEW_PROPERTY_ID } from '@hawthorn/services/config';
import { CcTabs, CcTab, CcTabPanel } from '@hawthorn/components/tabs';
import ImageUpload from '@hawthorn/components/image-upload';
import CdnImage from '@hawthorn/components/cdn-image';
import { properties } from '@hawthorn/services/properties';
import { planner } from '@hawthorn/services/planner';
import { budgeter } from '@hawthorn/services/budgeter';
import { users } from '@hawthorn/services/users';
import { ui } from '@hawthorn/services/ui';
import EditDescription from './edit-description';
import EditFinancial from './edit-financial';
import EditAccess from './edit-access';
import EditReport from './edit-report';
import BulkImport from './bulk-import';

const maxWidth = 980;
const tabMargin = 40;
const footerHeight = 112;

const styles = theme => ({
  page: {
    padding: `${footerHeight}px 1em`,
    width: '100%',
    minHeight: '100%',
    backgroundColor: ui.colours.backgroundLight2
  },

  property: {
    margin: 'auto',
    width: '100%',
    maxWidth: maxWidth
  },

  columns: {
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },

  columnPhoto: {
    // width: '40%',
    marginTop: tabMargin
  },

  columnForm: {
    // width: '60%',
    marginTop: tabMargin
  },

  fullWidthTab: {
    marginTop: tabMargin,
    paddingBottom: 60
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: footerHeight,
    backgroundColor: ui.colours.background,
    zIndex: 10
  },

  footerContent: {
    margin: 'auto',
    maxWidth: maxWidth,
    height: '100%'
  }
});

const DEFAULT_PROPERTY = {
  address: '',
  strataNumber: '',
  managerName: '',
  ocCount: 1,
  units: 1,
  lots: 1,
  premise: '',
  description: '',
  notes: '',
  inspectionDetails: 'Budget Version: \nAuthorised By: \nDate: \nInspection Date: \nAssessed By:',
  active: true
};

const DEFAULT_BUDGET = budgeter.budgetToLocal(budgeter.defaultOptions());

const TAB_MAPPING = {
  description: 0,
  financial: 1,
  report: 2,
  access: 3,
  import: 4
};

class PropertiesEdit extends Component {
  state = {
    // UI
    saving: false,
    restoreSummary: false,
    selectedTab: TAB_MAPPING.description,
    property: Object.assign(DEFAULT_PROPERTY, { id: v4() }),
    budgetOptions: DEFAULT_BUDGET,
    userIndex: { }
  };

  unsubscribe = { };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { properties, budget, history, match } = nextProps;

    const activePropertyId = match.params.id;

    // When new active property, nothing to restore
    if (activePropertyId === 'new') {
      return prevState;
    }

    // When properties loaded but no activePropertyId exists, redirect to properties
    if (Object.keys(properties).length > 0 && activePropertyId && !properties[activePropertyId]) {
      return history.push(routes.PROPERTIES);
    }

    let state = {
      ...prevState
    };

    if (state.property === DEFAULT_PROPERTY && (properties || { })[activePropertyId]) {
      state.property = properties[activePropertyId];
    }

    if (isEqual(state.budgetOptions, DEFAULT_BUDGET) && (budget || { }).options) {
      state.budgetOptions = budgeter.budgetToLocal(budget.options);
    }

    return state;
  }

  componentDidMount() {
    const { dispatch, match, selectProperty, authUser, location } = this.props;

    selectProperty(match.params.id);

    this.unsubscribe.property = properties.getProperty(dispatch, match.params.id);
    this.unsubscribe.budget = planner.getBudget(dispatch, match.params.id);

    // Get users (to-do: add Admin check here)
    users.find().then(users => {
      let { userIndex } = this.state;

      userIndex = users
        .reduce((index, user) => Object.assign(userIndex, { [user.id]: user }), userIndex);

      this.setState({ userIndex });
    });

    // Set tab from query param
    const query = qs.parse(location.search.substring(1));

    if (TAB_MAPPING[query.tab] !== undefined) {
      this.setState({
        selectedTab: TAB_MAPPING[query.tab],
        restoreSummary: query.tab === 'financial' || query.tab === 'report'
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribe.property();
    this.unsubscribe.budget();
  }

  setTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  }

  updateProperty = property => {
    const { properties, activePropertyId } = this.props;

    this.setState({
      property: {
        ...properties[activePropertyId],
        ...this.state.property,
        ...property
      }
    });
  }

  updateBudget = budgetOptions => {
    this.setState({
      budgetOptions: {
        ...this.state.budgetOptions,
        ...budgetOptions
      }
    });
  }

  updateUsers = ({ property, userIndex }) => {
    const { properties, activePropertyId } = this.props;

    let state = {
      property: {
        ...properties[activePropertyId],
        ...this.state.property,
        ...property
      }
    };

    if (userIndex) {
      state.userIndex = userIndex;
    }

    this.setState(state);
  }

  cancel(activePropertyId) {
    const { history } = this.props;
    
    // Cancel will go back unless there's nothing on the stack
    if (history.length) {
      history.goBack();
    }
    else if (activePropertyId === NEW_PROPERTY_ID) {
      history.push(routes.PROPERTIES);
    }
    else {
      history.push(routes.PLANNING.replace(':id', activePropertyId));
    }
  }

  save(activePropertyId) {
    const { history, update } = this.props;
    const { property, budgetOptions, userIndex, restoreSummary } = this.state;

    activePropertyId = NEW_PROPERTY_ID ? property.id : activePropertyId;

    this.setState({ saving: true });

    // Are there any new users that need to be created/invited?
    const invitees = Object.keys(userIndex)
      .filter(id => userIndex[id].invite && property.users.includes(id))
      .map(id => userIndex[id]);

    users.inviteUsers(invitees)
      .then(() => properties.updateProperty(property))
      .then(() => budgeter.updateBudgetOptions(activePropertyId, budgeter.localToBudget(budgetOptions)))
      .then(() => history.push(`/planning/${activePropertyId}?expand=${restoreSummary}`))
      .catch(err => {
        console.error('ERROR', err);
        this.setState({ saving: false });
      });
  }

  render() {
    const {
      classes,
      properties,
      categories,
      activePropertyId,
      authUser
    } = this.props;

    const { selectedTab, property, budgetOptions, userIndex, saving } = this.state;

    const storedProperty = properties[activePropertyId];

    return (
      <div className={classes.page}>
        <div className={classes.property}>
          { !!property &&
            <div>
              <Typography gutterBottom variant="h1" component="h2"><strong>{storedProperty ? storedProperty.address : 'New Property'}</strong></Typography>

              { /* Tab Selection */ }
              <CcTabs value={selectedTab} onChange={this.setTab} indicatorColor="primary" textColor="primary">
                <CcTab label="Description"></CcTab>
                <CcTab label="Financial Variables"></CcTab>
                <CcTab label="Report"></CcTab>

                { /* Only show permissions when admin */ }
                { authUser && authUser.admin &&
                  <CcTab label="Access/Permissions"></CcTab>
                }

                { /* Only show import when admin */ }
                { authUser && authUser.admin &&
                  <CcTab label="Bulk Import"></CcTab>
                }
              </CcTabs>

              { /* Hide photo column when import tab */ }
              { selectedTab < 2 &&
                <Grid container className={classes.columns} spacing={6}>
                  <Grid item className={classes.columnPhoto} xs sm={4}>
                    <Grid container direction="column" spacing={2}>
                      { property.photo &&
                        <Grid item>
                          <CdnImage path={`properties/${property.id}/${property.photo.key}`} alt={`Photo of ${property.address}`}></CdnImage>
                        </Grid>
                      }
                      
                      <Grid item>
                        <ImageUpload className={classes.photoUpload} path={`properties/${property.id}`} complete={key => this.updateProperty({ photo: { key } })}></ImageUpload>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.columnForm} xs sm={8}>
                    { /* Tab Content */ }
                    <CcTabPanel value={selectedTab} index={0}>
                      <EditDescription property={property} authUser={authUser} onChange={value => this.updateProperty(value)}></EditDescription>
                    </CcTabPanel>

                    <CcTabPanel value={selectedTab} index={1}>
                      <EditFinancial budgetOptions={budgetOptions} onChange={value => this.updateBudget(value)}></EditFinancial>
                    </CcTabPanel>
                  </Grid>
                </Grid>
              }

              { /* These tabs work better at full width */ }
              <CcTabPanel className={classes.fullWidthTab} value={selectedTab} index={2}>
                <EditReport property={property}></EditReport>
              </CcTabPanel>

              <CcTabPanel className={classes.fullWidthTab} value={selectedTab} index={3}>
                <EditAccess property={property} userIndex={userIndex} onChange={value => this.updateUsers(value)}></EditAccess>
              </CcTabPanel>

              <CcTabPanel className={classes.fullWidthTab} value={selectedTab} index={4}>
                <BulkImport property={property} categories={categories}></BulkImport>
              </CcTabPanel>

              <div className={classes.footer}>
                <Grid className={classes.footerContent} container justify="space-between" alignItems="center">
                  <Grid item>
                    <Button aria-label="Cancel" color="primary" onClick={() => this.cancel(activePropertyId)}>
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    { saving &&
                      <CircularProgress />
                    }

                    { !saving &&
                      <Button aria-label="Save Changes" color="primary" variant="outlined" onClick={() => this.save(activePropertyId)}>
                        Save Changes
                      </Button>
                    }
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  properties: state.property.properties,
  categories: state.budget.categories,
  budget: state.budget,
  activePropertyId: state.uiPlanning.activePropertyId,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  selectProperty: propertyId => dispatch({ type: 'SELECT_PROPERTY', propertyId }),
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorisation(authCondition),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(PropertiesEdit));
