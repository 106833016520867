import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 } from 'uuid';
import { users } from "@hawthorn/services/users";

// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function renderInput(inputProps) {
  const { InputProps, classes, ref, value, ...other } = inputProps;

  return (
    <TextField
      value={value}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderResults({ results, getItemProps, highlightedIndex, selectedItem }) {
  return (
    <div>
      { !results && <p style={{ padding: '2px 1em' }}>Loading...</p> }

      { results && results.length === 0 && <p style={{ padding: '5px 1em' }}>No results found</p> }
      
      { results &&
        results.map((user, index) =>
          renderUsers({
            user,
            index,
            itemProps: getItemProps({ item: user }),
            highlightedIndex,
            selectedItem,
          }))
      }
    </div>
  );
}

function renderUsers(userProps) {
  const { user, index, itemProps, highlightedIndex, selectedItem } = userProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || { name: '' }).name.indexOf(user.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={user.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <span>{user.name}</span>
      <span style={{ marginLeft: '1em', opacity: 0.6 }}>{user.email}</span>
    </MenuItem>
  );
}

renderUsers.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  user: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});


class UserSelect extends Component {
  state = {
    inputValue: '',
    users: null,
    results: null
  };

  retrieveUsers() {
    // Cached
    if (this.state.users)
      return Promise.resolve(this.state.users);

    return users.find();
  }

  searchUsers(event) {
    const value = event.target.value;

    // Ensure one-way binding works for textfield
    this.setState({ inputValue: value });

    // No way to do text-search in Firebase so filter client-side
    this.retrieveUsers()
      .then(users => {
        const regExp =  new RegExp(value, 'i');
        const results = users
          .filter(user => regExp.test(user.name) || regExp.test(user.email))
          .sort((a, b) => a.name.localeCompare(b.name));

        // Add invite option if a valid email
        if (EMAIL_REGEX.test(value)) {
          results.push({
            id: v4(),
            name: 'Send Invite To',
            email: value,
            invite: true
          });
        }

        this.setState({ results, users });
      })
  }

  emitOnChange(event) {
    const { onChange } = this.props;

    // Clear text input
    this.setState({ inputValue: '' });

    onChange(event);
  }

  stateReducer(state, changes) {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        // Emit change then clear after selection
        this.emitOnChange(changes.selectedItem);

        // Clear selectedItem after selection
        return {
          ...changes,
          selectedItem: null
        }
      default:
        return changes
    }

    return changes;
  }

  render() {
    const { classes } = this.props;
    const { results, inputValue } = this.state;

    return (
      <Downshift stateReducer={(state, changes) => this.stateReducer(state, changes)} onChange={event => this.emitOnChange(event)} itemToString={item => (item || { email: '' }).email}>
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Search by Name or Email Address',
            onChange: value => this.searchUsers(value),
            value: inputValue
          });

          return (
            <div className={classes.container}>
              {renderInput({
                label: 'Add User',
                fullWidth: true,
                variant: 'outlined',
                classes,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    { renderResults({ results, getItemProps, highlightedIndex, selectedItem }) }
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export default compose(
  withStyles(styles)
)(UserSelect);

