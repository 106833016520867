import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import TaskCard from './task-card';
import TaskForecast from './task-forecast';

const styles = theme => ({
  row: {
    position: 'relative'
  },

  forecast: {
    position: 'absolute',
    left: 320,
    top: 0,
    bottom: 0
  }
});

class TaskRow extends Component {
  render() {
    const {
      classes,
      task,
      scheduled,
      odd
    } = this.props;

    return (
      <div className={classes.row}>
        <TaskCard task={task} odd={odd}></TaskCard>

        <div className={classes.forecast}>
          <TaskForecast task={task} scheduled={scheduled}></TaskForecast>
        </div>
      </div>
    )
  }
}
  
const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({

});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TaskRow);
