import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faEllipsisV,
  faPlusCircle,
  faMinusCircle,
  faCog,
  faTag,
  faTools } from '@fortawesome/free-solid-svg-icons'

// Services
import { routes } from '@hawthorn/services/config';
import Nav from '@hawthorn/components/nav';
import withAuthentication from '@hawthorn/components/withAuthentication';
import { ui } from '@hawthorn/services/ui';

// Screens
import SignIn from './sign-in/sign-in';
import Register from './register/register';
import Properties from './properties/properties';
import PropertiesEdit from './properties-edit/properties-edit';
import Planning from './planning/planning';
import './app.css';

// Load Font Awesome icons
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faEllipsisV);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCog);
library.add(faTag);
library.add(faTools);

const rubik = "'Rubik', Arial, sans-serif";

const theme = createMuiTheme({
  typography: {
    fontFamily: rubik,
    h1: {
      fontSize: 36,
      fontWeight: 300
    },
    h2: {
      fontSize: 30,
      fontWeight: 300
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
      color: '#757575'
    },
    h4: {
      color: ui.colours.primary,
      fontSize: 18
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 500
    },
    button: {
      fontSize: 14
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: ui.colours.primary,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#ff8f5e',
      main: '#ff8f5e',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  overrides: {
    MuiButton: {
      root: {
        borderWidth: 2,
        textTransform: 'capitalize'
      },
      outlined: {
        borderWidth: '2px !important',
      },
      outlinedPrimary: {
        color: ui.colours.primaryLight,
        border: `2px solid ${ui.colours.primaryLight}`,

        '&:hover': {
          color: ui.colours.primaryDark,
          border: `2px solid ${ui.colours.primaryDark}`,
        }
      }
    },

    MuiPopover: {
      paper: {
        border: `1px solid ${ui.colours.border}`,
        borderRadius: 8,
        boxShadow: 'none',
        marginTop: -5
      }
    }
  }
});

class App extends Component {
  render() {
    const {
      authUser
    } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Grid style={{ height: '100%', paddingTop: authUser ? 64: 0 }} container justify="center" alignItems="center">
            { !!authUser && <Nav /> }

            <Route
              exact path={routes.SIGN_IN}
              component={() => <SignIn />}
            />
            <Route
              exact path={routes.REGISTER}
              component={() => <Register />}
            />
            <Route
              exact path={routes.PROPERTIES}
              component={() => <Properties />}
            />
            <Route
              exact path={routes.PROPERTIES_EDIT}
              component={() => <PropertiesEdit />}
            />
            <Route
              exact path={routes.PLANNING}
              component={Planning}
            />
          </Grid>
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(
  withAuthentication,
  connect(mapStateToProps)
)(App);


