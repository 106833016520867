const INITIAL_STATE = {
  properties: { }
};

const handlers = {
  // PROPERTY_CHANGE: (state, { properties }) => ({
  //   ...state,
  //   properties: properties
  // }),
  
  PROPERTIES_UPDATE: (state, { properties }) => ({
    ...state,
    properties: properties
  })
};

export default {
  initialState: INITIAL_STATE,
  handlers: handlers
};
