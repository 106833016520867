const INITIAL_STATE = {
  showNewProperty: false
};

const handlers = {
  NEW_PROPERTY: (state) => ({
    ...state,
    showNewProperty: true
  }),

  CANCEL_NEW_PROPERTY: (state) => ({
    ...state,
    showNewProperty: false
  })
};

export default {
  initialState: INITIAL_STATE,
  handlers: handlers
};
