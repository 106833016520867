import React, { Component } from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import propertiesEditStyles from './properties-edit-styles';


const styles = theme => ({
  ...propertiesEditStyles,

  checkbox: {
    marginTop: -8,

    '& .MuiFormControlLabel-label': {
      fontWeight: 500
    },

    '& .MuiSvgIcon-root': {
      fontSize: 34
    }
  }
});


class EditFinancial extends Component {
  updateValue = field => (event, value) => {
    const newValue = (event.target.value || value || '');

    this.props.onChange({ [field]: newValue });
  }

  updateCheckboxValue = field => (event, value) => {
    this.props.onChange({ [field]: value });
  };

  updateDateValue = field => value => {
    this.props.onChange({ [field]: value });
  }

  render() {
    const { classes, budgetOptions } = this.props;

    return (
      <Grid container direction="column" spacing={3}>
        {/* Inflations/Interest */}
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={4}>
              <label className={classes.label}>Inflation rate</label>
              <TextField
                type="number"
                value={budgetOptions.inflation}
                onChange={this.updateValue('inflation')}
                inputProps={{
                  step: '0.5'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
                variant="outlined"
                className={classes.input} />

            </Grid>

            <Grid item xs={4}>
              <label className={classes.label}>Budget inflation</label>
              
              <TextField
                type="number"
                value={budgetOptions.contributionInflation}
                onChange={this.updateValue('contributionInflation')}
                inputProps={{
                  step: '0.5'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={4}>
              <label className={classes.label}>Interest rate</label>
              
              <TextField
                type="number"
                value={budgetOptions.interest}
                onChange={this.updateValue('interest')}
                inputProps={{
                  step: '0.5'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
                variant="outlined"
                className={classes.input} />
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>Starting Balance</label>

              <TextField
                type="number"
                className={classes.input}
                id="startBalance"
                value={budgetOptions.startBalance}
                onChange={this.updateValue('startBalance')}
                inputProps={{
                  step: '1000'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                variant="outlined" />
            </Grid>

            <Grid item xs={6}>
              <label className={classes.label}>Commencement Date</label>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.input}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={budgetOptions.commencementDate}
                  onChange={this.updateDateValue('commencementDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth className={classes.margin}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox color="primary" checked={budgetOptions.gstRegistered} onChange={this.updateCheckboxValue('gstRegistered')} value="gstRegistered" />
                  }
                  label="GST Registered"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles)
)(EditFinancial);

